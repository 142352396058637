import React from 'react';
import IndustryImg from '../../assets/img/verticals/industry.png';
import Footer from '../common/Footer';
import Header from '../common/Header';
import TopHeader from '../homepage/TopHeader';

const Industry = () => {
    return (
        <>
            <TopHeader />
            <Header />
            <section className="my-5 open_overlay_sections_none py-5">
                <div>
                <section className=" margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                    <div id='salesforce-support' className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center">
                        <div
                            className="col-lg-6 text-center text-lg-start mt-4 mt-md-0"
                            data-aos="fade-up-right"
                            data-aos-duration="1500"
                            >
                            <img
                                className="w-75 "
                                src={IndustryImg}
                                alt=""
                            />
                        </div>
                        <div
                        className="col-lg-6  mt-5 mt-md-0"
                        data-aos="fade-up-left"
                        data-aos-duration="1500"
                        >
                            <div className="border-start border-4 border-danger ps-3 mb-4 mb-lg-4">
                                <h3 className="d-inline fw-light mb-0 text-normal">
                                Software Development
                                </h3>
                                <h2 className=" text-danger">
                                Industrial Software Development & Solutions 
                                </h2>
                                <p className="main_para mb-5">
                                L2 Global Technologies pvt ltd offers digital transformation services through innovating manufacturing industry with various solutions.
                                </p>
                            </div>
                            <p className="main_para mt-4">
                           
                            Industrial Automation solution development is a process by which companies can increase their efficiency and productivity in their industrial processes. <br /><br /> We offer a comprehensive suite of services that can help businesses design, develop, and deploy custom automat
                            </p>
                        </div>
                    </div>
                    </div>
                </section>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Industry;