import React from 'react';
import HospitalityImg from '../../assets/img/verticals/hospitality-industry.png';
import Footer from '../common/Footer';
import Header from '../common/Header';
import TopHeader from '../homepage/TopHeader';

const Hospitality = () => {
    return (
        <>
            <TopHeader />
            <Header />
            <section className="my-5 open_overlay_sections_none py-5">
                <div>
                <section className=" margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                    <div id='salesforce-support' className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center">
                        <div
                            className="col-lg-6 text-center text-lg-start mt-4 mt-md-0"
                            data-aos="fade-up-right"
                            data-aos-duration="1500"
                            >
                            <img
                                className="w-75 "
                                src={HospitalityImg}
                                alt=""
                            />
                        </div>
                        <div
                        className="col-lg-6  mt-5 mt-md-0"
                        data-aos="fade-up-left"
                        data-aos-duration="1500"
                        >
                            <div className="border-start border-4 border-danger ps-3 mb-4 mb-lg-4">
                                <h3 className="d-inline fw-light mb-0 text-normal">
                                Software Development
                                </h3>
                                <h2 className=" text-danger">
                                Hospitality Software Development for Hotels and Resorts
                                </h2>
                                <p className="main_para mb-5">
                                We specialize in working with hotels & resorts as their software development partners.
                                </p>
                            </div>
                            <p className="main_para mt-4">
                             We understand that nowadays technology plays a key role in branding and day to day operation of hotels. However, every hotel has a unique set of requirements and Technology must not only help the hotel’s staff in doing their work in an improved way, but it should also help the hotel in increasing its business. <br /><br /> We develop software that is easy to use for the hotel’s staff and guests to give them a cutting edge over their competitors.
                            </p>
                        </div>
                    </div>
                    </div>
                </section>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Hospitality;