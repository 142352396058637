import React, { useEffect } from 'react';
import BgImg from '../../assets/img/hero/heroimg2.webp';
import { autoScrollUp } from '../common/autoScrollUp';
import Footer from '../common/Footer';
import Header from '../common/Header';
import Cards from '../homepage/Cards';
import Services from '../homepage/Services';
import TopHeader from '../homepage/TopHeader';
import styles from './ServicePage.module.css';

const ServicePage = () => {
    useEffect(() => {
        autoScrollUp()
    },[])
    return (
        <div>
            <TopHeader />
            <Header />
            <div className="position-relative">
            <img className={`w-100 ${styles.service_img}`} src={BgImg} alt="" />
            <div className="position-absolute top-0 h-100 w-100 d-flex flex-column justify-content-center">
              <div className="container">
                <div className="row justify-content-lg-between justify-content-center">
                    <h1 className="text-white fw-extrabold pe-lg-5 me-lg-5 text-center">
                      Services
                    </h1>
                </div>
              </div>
            </div>
          </div>
            <Services services='' animate="" />
            <Cards />
            <Footer />
        </div>
    );
};

export default ServicePage;