import React from "react";
import { TeamCardsData } from "../common/Content";

const TeamCards = () => {
  return (
    <div className="container">
      <div className="row py-5 justify-content-center">
        {TeamCardsData &&
          TeamCardsData.map((value, index) => {
            return (
              <div key={index} className="col-xl-4 col-md-6 col-sm-9 col-11 my-sm-5 my-3">
                <div
                  className="teamcard h-100 position-relative cursor-pointer"
                >
                  <img
                    className="mx-auto w-25 d-block margin-bottom-19"
                    src={value.img}
                    alt=""
                  />
                  <div className="bg-white box-shadow py-3">
                    <h1 className="font-lg text-center fw-semibold">
                      {value.heading}
                    </h1>
                    <p className="text-center ittalic">{value.text}</p>
                  </div>
                  <div className="position-absolute top-0 h-100 w-100 ">
                    <div className="colored-bg px-4 py-2 h-100 ">
                      <p className="text-center text-white font-normal mb-2">
                        Felis, semper feugiat id sapien tortor nulla sed cras.
                        Imperdiet aliquet sapien ac, amet neque, urna nunc,
                        quis. Vel, viverra feugiat pellentesque volutpat
                        volutpat. Malesuada sapien vitae diam duis lorem et
                        justo, consequat id. Et morbi quisque purus sit
                        vestibulum, odio neque. At mauris.
                      </p>
                      <p className="text-center text-white font-lg my-0">
                        IMPORTANT FACT
                      </p>
                      <p className="text-center text-white ittalic font-normal">
                        Malesuada sapien vitae diam duis lorem et justo,
                        consequat id.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TeamCards;
