import React, { useEffect } from 'react';
import AboutImg from '../../assets/img/about/AboutImg.webp';
import IndustriesBottomWave from '../../assets/img/form/FromBottomWave.webp';
import IndustriesTopWave from '../../assets/img/form/FromTopWave.webp';
import BgImg from '../../assets/img/hero/heroimg2.webp';
import { autoScrollUp } from '../common/autoScrollUp';
import { workFlowCard } from '../common/Content';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { IndustryIcon, ServicesCircleIcon, ServicesCloudIcon, ServicesHomeIcon, ServicesMobileIcon } from '../common/Icons';
import TopHeader from '../homepage/TopHeader';
import styles from '../ServicePage/ServicePage.module.css';


const IndustriesPage = () => {
    useEffect(() => {
        autoScrollUp()
    },[])
    return (
        <div>
            <TopHeader />
            <Header />
            <section className="position-relative">
                <img className={`w-100 ${styles.service_img}`} src={BgImg} alt="" />
                <div className="position-absolute top-0 h-100 w-100 d-flex flex-column justify-content-center">
                <div className="container">
                    <div className="row justify-content-lg-between justify-content-center">
                        <h1 className="text-white fw-extrabold pe-lg-5 me-lg-5 text-center">
                            Industries
                        </h1>
                    </div>
                </div>
                </div>
            </section>
           
            <section className="my-5 open_overlay_sections_none">
                <div className="w-100 FromTopWave">
                <img className="w-100" src={IndustriesTopWave} alt="" />
                </div>
                <div>
                <section className="form_bg margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                    <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center">
            <div
              className="col-lg-6 mb-4 mb-lg-0"
              data-aos="fade-up-right"
              data-aos-duration="1500"
            >
              <div>
                <div className="d-flex mb-3 mb-lg-4 mt-5 card_shadow p-3">
                  <div>
                    <span>
                      <ServicesCloudIcon />
                    </span>
                  </div>
                  <div>
                    <h2 className="main_para font-md fw-semibold">
                    Real Estate 
                    </h2>
                    <p className="main_para mb-0">
                    We serve innovative solutions to real estate companies to help them align their businesses with industry 4.0 advancements. From process automation solutions to reality technologies to bring new avenues of engagement, we deliver holistic solutions.
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-3 mb-lg-4 card_shadow p-3">
                  <div>
                    <span>
                      <ServicesMobileIcon />
                    </span>
                  </div>
                  <div className="ms-3">
                    <h2 className="main_para font-md fw-semibold">
                    IT & Software 
                    </h2>
                    <p className="main_para mb-0">
                    We develop innovative digital solutions for ICT and software companies to adopt the latest technologies to solve complex business problems. We collaborate to build a mutually beneficial environment for both us and our clients
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-3 mb-lg-4 card_shadow p-3">
                  <div>
                    <span>
                      <ServicesCircleIcon />
                    </span>
                  </div>
                  <div>
                    <h2 className="main_para font-md fw-semibold">  
                    Telecom Industry
                    </h2>
                    <p className="main_para mb-0">
                    In an era of inter-connectivity, we help telecom enterprises to stay up-to-date with the latest technological solutions. Whether to help cater services to millions of users or to adopt the latest enterprise solutions, we provide holistic software and technical solutions for telecom enterprises.
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-3 mb-lg-4 card_shadow p-3">
                  <div>
                    <span>
                      <ServicesHomeIcon />
                    </span>
                  </div>
                  <div>
                    <h2 className="main_para font-md fw-semibold">
                      Education
                    </h2>
                    <p className="main_para mb-0">
                    All in One Learning Platform for Education and Training
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-5"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            >
            {/* moving_animation_img */}
              <div className="">
                <img style={{width:'85%'}} src={AboutImg} alt="" />
              </div>
            </div>
          </div>
                    </div>
                </section>
                <div className="w-100 FromBottomWave">
                        <img className="w-100" src={IndustriesBottomWave} alt="" />
                    </div>
                </div>
            </section>
            <section>
                    <div className="text-center mb-4 mb-lg-4">
                        <h4 className="d-inline mb-0 text-danger">
                        Process
                        </h4>
                        <h1 className="mb-2">
                        Work Process
                        </h1>
                        <p className="d-inline mb-0">
                        Here's a closer look at our work process
                        </p>
                    </div>
                    <div className='container'>

                    <div className="row py-5 px-4 justify-content-center">
                    {workFlowCard.map((oprt, index)=> {
                        return (
                        <div className="col-xl-4 col-md-6 col-sm-9 col-11 my-sm-3 my-3">
                            <div
                            className={`teamcard h-100 position-relative cursor-pointer p-5 ${styles.industries_box}`}
                            key={index}
                            >
                                <IndustryIcon />
                                <p className="mb-0 fw-bold mt-5 mb-3">
                                {oprt.title}
                                </p>
                                <p className="mb-2 opacity-75">
                                {oprt.text}
                                </p>
                            </div>
                        </div>
                        );
                    })}
                    </div>
                    </div>
                </section>
            <Footer />
        </div>
    );
};

export default IndustriesPage;