import React from "react";
import FormBoy from "../../assets/img/form/FormBoy.png";
import FromBottomWave from "../../assets/img/form/FromBottomWave.webp";
import FromTopWave from "../../assets/img/form/FromTopWave.webp";

const Form = () => {
  return (
    <>
      <section className="my-5 open_overlay_sections_none">
        <div className="w-100 FromTopWave">
          <img className="w-100" src={FromTopWave} alt="" />
        </div>
        <div>
          <section className="form_bg margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0 form-padding">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-6 text-center text-lg-start"
                  data-aos="fade-up-right"
                  data-aos-duration="1500"
                >
                  <img
                    className="w-75 moving_animation_img"
                    src={FormBoy}
                    alt=""
                  />
                </div>
                <div
                  className="col-lg-6 mt-5"
                  data-aos="fade-up-left"
                  data-aos-duration="1500"
                >
                  <div className="  mb-4 mb-lg-5">
                    <h2 className="d-inline main_heading px-3 py-2 bg_red mb-0">
                      Enquiry
                    </h2>
                  </div>
                  <p className="main_para">
                  Please complete the contact form today if you would like to find out more about the company or our solutions. We would love to start a conversation. Don’t like Filling up forms ? Mail us
                    Then Company@email.com
                  </p>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        id="inputEmail4"
                        placeholder="Name"
                      />
                    </div>
                    <div className="col-md-6 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        placeholder="Company Name"
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail4"
                        placeholder="Email"
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <input
                        type="tel"
                        className="form-control"
                        id="inputPassword4"
                        placeholder="Phone "
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <input
                        type="text"
                        className="form-control"
                        id="inputEmail4"
                        placeholder="Service Looking for"
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        placeholder="Industry"
                      />
                    </div>
                    <div className="mt-2">
                      <textarea
                        type="text"
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Service Looking for"
                      ></textarea>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button className="font-normal fw-bold text-white form_btn btn_hover">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="w-100 FromBottomWave">
          <img className="w-100" src={FromBottomWave} alt="" />
        </div>
      </section>
    </>
  );
};

export default Form;
