import React from "react";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import About from "../components/homepage/About";
import Cards from "../components/homepage/Cards";
import Contact from "../components/homepage/Contact";
import FeedBackSlider from "../components/homepage/FeedBackSlider";
import Form from "../components/homepage/Form";
import Hero from "../components/homepage/Hero";
import Industries from "../components/homepage/Industries";
import Services from "../components/homepage/Services";
import Team from "../components/homepage/Team";
import TopHeader from "../components/homepage/TopHeader";

const Home = () => {
  return (
    <div>
      <TopHeader />
      <Header />
      <Hero />
      <About />
      <Industries />
      <Services services={"Services"} animate="moving_animation_img"/>
      <Cards />
      <FeedBackSlider />
      <Team />
      <Form />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
