import React, { useEffect } from 'react';
import Slider from 'react-slick/lib/slider';
import Carusal_1 from '../../assets/img/expertise/carusal_1.jpg';
import Card_1 from '../../assets/img/expertise/expt_1.svg';
import BgImg from '../../assets/img/hero/heroimg2.webp';
import { autoScrollUp } from '../common/autoScrollUp';
import { opportunityCard } from '../common/Content';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { Calander, User } from '../common/Icons';
import TopHeader from '../homepage/TopHeader';
import styles from '../ServicePage/ServicePage.module.css';

const ExpertisePage = () => {
    useEffect(() => {
        autoScrollUp()
    },[])
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoScrollUp: true,
      };
    return (
        <div>
            <TopHeader />
            <Header />
            <section className="position-relative">
                <img className={`w-100 ${styles.service_img}`} src={BgImg} alt="" />
                <div className="position-absolute top-0 h-100 w-100 d-flex flex-column justify-content-center">
                <div className="container">
                    <div className="row justify-content-lg-between justify-content-center">
                        <h1 className="text-white fw-extrabold pe-lg-5 me-lg-5 text-center">
                            Expertise
                        </h1>
                    </div>
                </div>
                </div>
            </section>
            <section>
                <div>
                    <Slider {...settings}>
                        <section className="my-5">
                            <div>
                            <section className="form_bg margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                                <div className="container py-5">
                                    <div className="row">
                                        <div
                                        className="col-lg-6 mt-5"
                                        data-aos="fade-up-right"
                                        data-aos-duration="1500"
                                        >
                                            <h5 className="mb-4">
                                            SOFTWARE DEVELOPMENT
                                            </h5>
                                            <h2 className="mb-4">
                                            Development Skills and Experience
                                            </h2>
                                            <p className="main_para mt-2">
                                            We have wide experience both in development platforms and business domains. 

                                            We don’t specialize in particular technologies, but we are definitely proficient and experienced with a wide array of tools and systems. Every product is unique and we try to fit the right team with the right skills for that particular product. Our teams have worked on web applications, created desktop applications and built numerous mobile apps.
                                            </p>
                                            <div className={`d-flex align-items-center justify-content-between p-4 w-75  ${styles.expertise_carusal_card} mt-5`}>
                                                <div>
                                                    <h6 className="main_para mt-2 fw-bold">
                                                        Project Name
                                                    </h6>
                                                    <p className="mt-2">Live Concert Audience Platform</p>
                                                </div>
                                                <div>
                                                    <h6 className="main_para mt-2 fw-bold">
                                                        Duration
                                                    </h6>
                                                    <p className="mt-2">3 Year</p>
                                                </div>
                                                <div>
                                                    <h6 className="main_para mt-2 fw-bold">
                                                        Budget
                                                    </h6>
                                                    <p className="mt-2">10M</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                        className="col-lg-6 text-center text-lg-end mt-4 mt-md-0"
                                        data-aos="fade-up-left"
                                        data-aos-duration="1500"
                                        >
                                        <img
                                            className="w-100"
                                            src={Carusal_1}
                                            alt=""
                                        />
                                    </div>
                                    </div>
                                </div>
                            </section>
                            </div>
                        </section>
                        <section className="my-5">
                            <div>
                            <section className="form_bg margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                                <div className="container py-5">
                                    <div className="row">
                                        <div
                                        className="col-lg-6 mt-5"
                                        data-aos="fade-up-right"
                                        data-aos-duration="1500"
                                        >
                                            <h5 className="mb-4">
                                            SOFTWARE QUALITY ASSURANCE
                                            </h5>
                                            <h2 className="mb-4">
                                            Great Design and Development 
                                            </h2>
                                            <p className="main_para mt-2">
                                            Great design and development goes nowhere without great quality. Our integrated quality assurance approach incorporates aspects of agile and lean development with the stability and reliability of traditional SQA process.

                                            We believe software quality assurance is only possible with a mixed set of procedures which should involve all members of the team collaborating with a dedicated team of SQA professionals.
                                            </p>
                                            <div className={`d-flex align-items-center justify-content-between p-4 w-75  ${styles.expertise_carusal_card} mt-5`}>
                                                <div>
                                                    <h6 className="main_para mt-2 fw-bold">
                                                        Project Name
                                                    </h6>
                                                    <p className="mt-2">Virtual Reality VR Game</p>
                                                </div>
                                                <div>
                                                    <h6 className="main_para mt-2 fw-bold">
                                                        Duration
                                                    </h6>
                                                    <p className="mt-2">1 Year</p>
                                                </div>
                                                <div>
                                                    <h6 className="main_para mt-2 fw-bold">
                                                        Budget
                                                    </h6>
                                                    <p className="mt-2">1M</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                        className="col-lg-6 text-center text-lg-end mt-4 mt-md-0"
                                        data-aos="fade-up-left"
                                        data-aos-duration="1500"
                                        >
                                        <img
                                            className="w-100"
                                            src={Carusal_1}
                                            alt=""
                                        />
                                    </div>
                                    </div>
                                </div>
                            </section>
                            </div>
                        </section>
                        <section className="my-5">
                            <div>
                            <section className="form_bg margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                                <div className="container py-5">
                                    <div className="row">
                                        <div
                                        className="col-lg-6 mt-5"
                                        data-aos="fade-up-right"
                                        data-aos-duration="1500"
                                        >
                                            <h5 className="mb-4">
                                            OUR EXPERTNESS
                                            </h5>
                                            <h2 className="mb-4">
                                            Areas of Expertise
                                            </h2>
                                            <p className="main_para mt-2">
                                            Because of our involvement with all kinds of projects our SQA teams are exposed to a variety of technology and business domains. This helps us to provide unique resources bringing in skills, experience and ideas from very different sectors.  
                                            </p>
                                            <div className={`d-flex align-items-center justify-content-between p-4 w-75  ${styles.expertise_carusal_card} mt-5`}>
                                                <div>
                                                    <h6 className="main_para mt-2 fw-bold">
                                                        Project Name
                                                    </h6>
                                                    <p className="mt-2">Startup Services</p>
                                                </div>
                                                <div>
                                                    <h6 className="main_para mt-2 fw-bold">
                                                        Duration
                                                    </h6>
                                                    <p className="mt-2">7 Year</p>
                                                </div>
                                                <div>
                                                    <h6 className="main_para mt-2 fw-bold">
                                                        Budget
                                                    </h6>
                                                    <p className="mt-2">10M</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                        className="col-lg-6 text-center text-lg-end mt-4 mt-md-0"
                                        data-aos="fade-up-left"
                                        data-aos-duration="1500"
                                        >
                                        <img
                                        // moving_animation_img
                                            className="w-100"
                                            src={Carusal_1}
                                            alt=""
                                        />
                                    </div>
                                    </div>
                                </div>
                            </section>
                            </div>
                        </section>
                    </Slider>
                </div>
            </section>
            <section className='mt-5'>
                    <div className="text-center mb-4 mb-lg-4">
                        <h4 className="d-inline mb-0 text-danger">
                        Blog
                        </h4>
                        <h1 className="mb-4">
                        Latest News
                        </h1>
                        <p className="d-inline mb-0">
                        In order not to miss any of its news, Global Technologies Solutions Group presents you its latest news,<br /> publications and events. You can also find this information on social networks.
                        </p>
                    </div>
                    <div className="container">
                        <div className="row py-5 justify-content-center">
                            {opportunityCard.map((value, index) => {
                                return (
                                    <div key={index} className="col-xl-4 col-md-6 col-sm-9 col-11 my-sm-3 my-3">
                                        <div
                                        className={`teamcard h-100 position-relative cursor-pointer p-4 ${styles.expertise_box}`}
                                        key={index}
                                        >
                                         <img className={`w-100 h-100 ${styles.service_img}`} src={Card_1} alt="" />
                                            <div className='d-flex align-items-center'>
                                                <p className="d-inline mb-4 fw-bold opacity-50 d-flex align-items-center">
                                                <Calander /> &nbsp; &nbsp; Oct 20, 2021
                                                </p>
                                                <p className="d-inline mb-4 ms-3 fw-bold opacity-50 d-flex align-items-center">
                                                <User /> &nbsp; Pulaknondi
                                                </p>
                                            </div>
                                            <h5 className="mb-3">
                                            Morbis dictumst mentaeos felis morbi magna perfect
                                            </h5>
                                            <div className="mt-5 text-left">
                                                <button className="font-normal fw-bold text-white form_btn btn_hover">
                                                Read More &nbsp; {">"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
            </section>
            <Footer />
        </div>
    );
};

export default ExpertisePage;