import React from 'react';
import HealthcareImg from '../../assets/img/verticals/health-care.png';
import Footer from '../common/Footer';
import Header from '../common/Header';
import TopHeader from '../homepage/TopHeader';

const Healthcare = () => {
    return (
        <>
            <TopHeader />
            <Header />
            <section className="my-5 open_overlay_sections_none py-5">
                <div>
                <section className=" margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                    <div id='salesforce-support' className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center">
                        <div
                            className="col-lg-6 text-center text-lg-start mt-4 mt-md-0"
                            data-aos="fade-up-right"
                            data-aos-duration="1500"
                            >
                            <img
                                className="w-75 "
                                src={HealthcareImg}
                                alt=""
                            />
                        </div>
                        <div
                        className="col-lg-6  mt-5 mt-md-0"
                        data-aos="fade-up-left"
                        data-aos-duration="1500"
                        >
                            <div className="border-start border-4 border-danger ps-3 mb-4 mb-lg-4">
                                <h3 className="d-inline fw-light mb-0 text-normal">
                                Healthcare Software 
                                </h3>
                                <h2 className=" text-danger">
                                Development Services
                                </h2>
                                <p className="main_para mb-5">
                                We are a leading and top healthcare software development company. 
                                </p>
                            </div>
                            <p className="main_para mt-4">
                            We work on various services in healthcare software development, which includes developing new software or applications, and enhancing or modifying an existing system. <br /><br /> Our healthcare software developers have also undertaken some projects that are specific to a certain area of the healthcare industry, such as medical records, billing and insurance, and personalized healthcare. <br /> <br /> In addition, we offer various integration and implementation like EHR and EMR integration, and Salesforce health cloud implementation services. We also provide migration, support, and maintenance for existing healthcare software systems. 
                            </p>
                        </div>
                    </div>
                    </div>
                </section>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Healthcare;