import React from "react";
import Modal from "react-bootstrap/Modal";
import { useScreenFixedProvider } from "../../components/context/ScreenFixedProvider";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-asik"
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className="px-4 px-md-5 pb-4 pb-md-5">
        <h4 className="text-center mb-4">Sign Up</h4>
            <div className="row">
                <form>
                    <div className="col-md-12 mt-2 mt-md-3">
                    <input
                        type="text"
                        className="form-control py-3 login_form"
                        id="inputEmail4"
                        placeholder="First name"
                    />
                    </div>
                    <div className="col-md-12 mt-2 mt-md-3">
                    <input
                        type="text"
                        className="form-control py-3 login_form"
                        id="inputPassword4"
                        placeholder="Last name"
                    />
                    </div>
                    <div className="col-md-612mt-2 mt-md-3">
                    <input
                        type="tel"
                        className="form-control py-3 login_form"
                        id="inputEmail4"
                        placeholder="Email address"
                    />
                    </div>
                    <div className="col-md-12 mt-2 mt-md-3">
                    <input
                        type="tel"
                        className="form-control py-3 login_form"
                        id="inputPassword4"
                        placeholder="Password "
                    />
                    </div>
                    <div className="col-md-12 my-2 my-md-3">
                    <input
                        id="check_box"
                        type="checkbox"
                        className="me-3 ms-1"
                    />
                    <label htmlFor="check_box"> I agree to receive email</label>
                    </div>
                    <button className="font-normal fw-bold w-100 text-white form_btn btn_hover mt-4">
                                Sign Up
                    </button>
                </form>
            </div>
      </Modal.Body>
    </Modal>
  );
}

const SignUp = () => {
    const { modalShow, setModalShow } = useScreenFixedProvider();
    
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default SignUp;
