import React from 'react';
import RetailImg from '../../assets/img/verticals/retail.png';
import Footer from '../common/Footer';
import Header from '../common/Header';
import TopHeader from '../homepage/TopHeader';

const Retail = () => {
    return (
        <>
            <TopHeader />
            <Header />
            <section className="my-5 open_overlay_sections_none py-5">
                <div>
                <section className=" margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                    <div id='salesforce-support' className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center">
                        <div
                            className="col-lg-6 text-center text-lg-start mt-4 mt-md-0"
                            data-aos="fade-up-right"
                            data-aos-duration="1500"
                            >
                            <img
                                className="w-75 "
                                src={RetailImg}
                                alt=""
                            />
                        </div>
                        <div
                        className="col-lg-6  mt-5 mt-md-0"
                        data-aos="fade-up-left"
                        data-aos-duration="1500"
                        >
                            <div className="border-start border-4 border-danger ps-3 mb-4 mb-lg-4">
                                <h3 className="d-inline fw-light mb-0 text-normal">
                                Retail Software Development
                                </h3>
                                <h2 className=" text-danger">
                                Custom retail software development
                                </h2>
                                <p className="main_para mb-5">
                                We offer a wide range of solutions such as ecommerce, pos, retail software, retail operations solutions that help businesses connect with customers and sell products and services. 
                                </p>
                            </div>
                            <p className="main_para mt-4">
                            
                            Custom retail software development services is to provide a tailor-made solution to fit specific needs. <br /><br /> Various solutions include software for tracking inventory, managing customer relationships, and processing sales. <br /><br /> We are a custom software development company working 
                            </p>
                        </div>
                    </div>
                    </div>
                </section>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Retail;