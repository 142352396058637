import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import About from "../../assets/img/about/about.png";
import Choice from "../../assets/img/about/choice.jpg";
import FromBottomWave from "../../assets/img/form/FromBottomWave.webp";
import FromTopWave from "../../assets/img/form/FromTopWave.webp";
import BgImg from '../../assets/img/hero/heroimg2.webp';
import { autoScrollUp } from '../common/autoScrollUp';
import Footer from '../common/Footer';
import Header from '../common/Header';
import TeamCards from '../homepage/TeamCards';
import TopHeader from '../homepage/TopHeader';
import styles from '../ServicePage/ServicePage.module.css';

const AboutUs = () => {
    useEffect(() => {
        autoScrollUp()
    },[])
    return (
        <div>
            <TopHeader />
            <Header />
            <section className="position-relative">
                <img className={`w-100 ${styles.service_img}`} src={BgImg} alt="" />
                <div className="position-absolute top-0 h-100 w-100 d-flex flex-column justify-content-center">
                <div className="container">
                    <div className="row justify-content-lg-between justify-content-center">
                        <h1 className="text-white fw-extrabold pe-lg-5 me-lg-5 text-center">
                            About Us
                        </h1>
                    </div>
                </div>
                </div>
            </section>
            <section className="my-5 open_overlay_sections_none">
                <div className="w-100 FromTopWave">
                <img className="w-100" src={FromTopWave} alt="" />
                </div>
                <div>
                <section className="form_bg margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                    <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-6 text-center text-lg-end mt-4 mt-md-0"
                            data-aos="fade-up-right"
                            data-aos-duration="1500"
                            >
                            <img
                                className="w-100 "
                                src={About}
                                alt=""
                            />
                        </div>
                        <div
                        className="col-lg-6 mt-5"
                        data-aos="fade-up-left"
                        data-aos-duration="1500"
                        >
                            <div className="  mb-4 mb-lg-4">
                                <h4 className="d-inline mb-0 text-danger">
                                About Us
                                </h4>
                            </div>
                            <h1 className="mb-5">
                            Global Technologies. 
                            </h1>
                            <h4 className="d-inline mb-0 text-danger mt-4">
                            Professional Design Agency to provide solutions
                            </h4>
                            <p className="main_para mt-4">
                            Global Technologies is recognized for its excellence in key technologies, and efficient project management.Global Technologies builds on its experience and specialized skills to provide national scale IT solutions. It has deep insight in biometrics information involving large population. With dynamic partnerships, Global Technologies provides turnkey solutions that are sustainable and meet international standards.
                            </p>
                            <div className="mt-5">
                            <Link to="/contact">
                                <button className="font-normal fw-bold text-white form_btn btn_hover">
                                Contact Us &nbsp; {">"}
                                </button>
                            </Link>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>
                </div>
                <div className="w-100 FromBottomWave">
                <img className="w-100" src={FromBottomWave} alt="" />
                </div>
            </section>
            <section className="my-5 open_overlay_sections_none">
                <div className="w-100 FromTopWave">
                <img className="w-100" src={FromTopWave} alt="" />
                </div>
                <div>
                <section className="form_bg margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                    <div className="container">
                    <div className="row">
                        <div
                        className="col-lg-6 mt-5"
                        data-aos="fade-up-right"
                        data-aos-duration="1500"
                        >
                            <div className="  mb-4 mb-lg-4">
                                <h4 className="d-inline mb-0 text-danger">
                                How We Are
                                </h4>
                            </div>
                            <h1 className="mb-5">
                            We are a digital and solution providing company.
                            </h1>
                            <h4 className="d-inline mb-0 text-danger mt-4">
                            That believes in the power of creative strategy and along with great design.
                            </h4>
                            <p className="main_para mt-4">
                            We work in mission critical environments with large volumes of sensitive data, so our highly experienced team strives to reduce risk, improve performance and promote sustainable business. We bring innovation not only in our technologies but also in the way we manage our projects and deliver our solutions. Our expertise in consultancy, project management and support combined with our relentless quest for customer satisfaction through on-time, on-budget and on-spec delivery of complex turnkey solutions, has earned Global Technologies an enviable reputation in the industry.
                            </p>
                            <div className="mt-5">
                            <Link to="/services">
                                <button className="font-normal fw-bold text-white form_btn btn_hover">
                                Discover More &nbsp; {">"}
                                </button>
                            </Link>
                            </div>
                        </div>
                        <div
                            className="col-lg-6 text-center text-lg-end mt-4 mt-md-0"
                            data-aos="fade-up-left"
                            data-aos-duration="1500"
                            >
                            <img
                                className="w-100"
                                src={Choice}
                                alt=""
                            />
                        </div>
                    </div>
                    </div>
                </section>
                </div>
                <div className="w-100 FromBottomWave">
                <img className="w-100" src={FromBottomWave} alt="" />
                </div>
            </section>
                {/* <section>
                    <div className="text-center mb-4 mb-lg-4">
                        <h4 className="d-inline mb-0 text-danger">
                        Meet Our Team
                        </h4>
                        <h1 className="mb-5">
                        Experience Team Members
                        </h1>
                        <h4 className="d-inline mb-0 text-danger">
                        Save your time and money by choosing our professional team.
                        </h4>
                    </div>
                    <TeamCards />
                </section> */}
            <Footer />
        </div>
    );
};

export default AboutUs;