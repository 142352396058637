import React from "react";
import Modal from "react-bootstrap/Modal";
import { useScreenFixedProvider } from "../../components/context/ScreenFixedProvider";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-asik"
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className="px-4 px-md-5 pb-4 pb-md-5">
        <h4 className="text-center mb-4">Sign In</h4>
            <div className="row">
                <form>
                    <div className="col-md-612mt-2 mt-md-3">
                    <input
                        type="tel"
                        className="form-control py-3 login_form"
                        id="inputEmail4"
                        placeholder="Email address"
                    />
                    </div>
                    <div className="col-md-12 mt-2 mt-md-3">
                    <input
                        type="tel"
                        className="form-control py-3 login_form"
                        id="inputPassword4"
                        placeholder="Password "
                    />
                    </div>
                    <button className="font-normal fw-bold w-100 text-white form_btn btn_hover mt-4">
                                Sign In
                    </button>
                </form>
            </div>
      </Modal.Body>
    </Modal>
  );
}

const SignIn = () => {
    const { signInModal, setSignInModal } = useScreenFixedProvider();
    
  return (
    <>
      <MyVerticallyCenteredModal
        show={signInModal}
        onHide={() => setSignInModal(false)}
      />
    </>
  );
};

export default SignIn;
