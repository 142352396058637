import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { default as img_1, default as img_6 } from '../../assets/img/trusted-companies/go-huffpost.png';
import { default as img_2, default as img_7 } from '../../assets/img/trusted-companies/logo-entrepreneur.png';
import img_3 from '../../assets/img/trusted-companies/logo-huffpost.png';
import { default as img_4, default as img_8 } from '../../assets/img/trusted-companies/logo-marketwatch.png';
import img_5 from '../../assets/img/trusted-companies/ogo-entrepreneur.png';


function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

  
const TrustedCompany = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [settings , setSettings] = useState({})
    
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const setting_1 = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay:true, 
    }
  const setting_2 = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay:true, 
    }

  useEffect(()=>{
    if(windowSize.innerWidth > 767){
        setSettings(setting_1)
    }else{
        setSettings(setting_2)
    }
  },[windowSize.innerWidth])

    return (
        <div className='container  mt-4 mt-lg-5'>
            <Slider className='react_slick' 
            {...settings}
            >
                <img src={img_1} alt="" />
                <img src={img_2} alt="" />
                <img src={img_3} alt="" />
                <img src={img_4} alt="" />
                <img src={img_5} alt="" />
                <img src={img_6} alt="" />
                <img src={img_7} alt="" />
                <img src={img_8} alt="" />
            </Slider>
        </div>
    );
};

export default TrustedCompany;