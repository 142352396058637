import React from "react";
import Slider from "react-slick";
import feedbacktopwave from "../../assets/img/feedback/feedbacktopwave.webp";
import feedbackbottomwave from "../../assets/img/form/FromBottomWave.webp";
import { FeedbackSliderData } from "../common/Content";
const FeedBackSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <>
      <div className="text-center my-4 my-lg-5">
        <h2 className="d-sm-inline d-block main_heading px-3 py-2 bg_red mb-0">
          Customers And Feedback
        </h2>
      </div>

      <div className="open_overlay_sections_none feedback-bg-img margin-top-minus-feedback position-relative mt-5">
        <div className=" position-absolute feedbacktopwave start-0 w-100 d-none d-lg-inline">
          <img className="w-100" src={feedbacktopwave} alt="" />
        </div>
        <div className="container pb-5 pb-lg-0">
          <Slider {...settings} className="feedback-slider  ">
            {FeedbackSliderData &&
              FeedbackSliderData.map((value, index) => {
                return (
                  <div key={index} className="d-flex flex-md-row flex-column justify-content-evenly text-center">
                    <div>
                      <img
                        className="w-100 mx-sm-0 mx-auto d-sm-block d-block man_img  "
                        src={value.img}
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-center flex-column pt-md-0 pt-4">
                      <div className="d-flex justify-content-center">
                        <img src={value.starimg} alt="" />
                        <img src={value.starimg} alt="" />
                        <img src={value.starimg} alt="" />
                        <img src={value.starimg} alt="" />
                        <img src={value.starimg} alt="" />
                      </div>
                      <p className="main_para mt-4 width_485">{value.para}</p>
                      <h1 className="font-lg fw-semibold">
                        {value.smallHeading}
                      </h1>
                      <p className="main_para">{value.text}</p>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
      
      <div className="w-100 FromBottomWave">
          <img className="w-100" src={feedbackbottomwave} alt="" />
        </div>
    </>
  );
};

export default FeedBackSlider;
