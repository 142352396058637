import React from "react";
import { ContactIco, EmailIco, LocationIco } from "../common/Icons";
import MyLocation from "../common/MyLocation";
const containerStyle = {
    width: '100vw',
    height: '100%'
  };
const Contact = () => {
  return (
    <>
      <div className="text-center pt-5 mb-lg-5 container">
        <h2 className="d-inline main_heading px-3 py-2 bg_red mb-0">
        Find Us on Map
        </h2>
        <p className="text-center mt-4">
        Browse through the most comprehensive map to get location of Global Technology Ltd.
        </p>
      </div>
      
     
      <div
        className="open_overlay_sections_none contact-section pt-5 position-relative"
        id="Contact"
        >
            <div className="">
            <div className="row justify-content-between mb-sm-5 mb-sm-3">
            <div
              className="col-lg-6 mt-lg-0 mt-5"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div className="w-100 MapImg2">
                <MyLocation containerStyle={containerStyle} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-5">
            <div className="d-block py-5 d-sm-flex align-items-center justify-content-center justify-content-sm-around">
                <div className="d-flex align-items-center mt-4">
                    <span className="bg-danger p-2 p-sm-3 rounded-circle">
                        <ContactIco/>
                    </span>
                    <div className='ms-5 ms-sm-1 ms-md-2 ms-lg-5'>
                        <h4 className="d-inline mb-2 text-danger">
                            Call Us
                        </h4>
                        <p className="main_para mt-2 mb-0">
                        + 91 &nbsp; 97403 39495
                        </p>
                    </div>
                </div>
                <div className="d-flex align-items-center mt-4">
                    <span className="bg-danger p-2 p-sm-3 rounded-circle">
                        <LocationIco/>
                    </span>
                    <div className='ms-5 ms-sm-1 ms-md-2 ms-lg-5'>
                        <h4 className="d-inline mb-2 text-danger">
                            Address
                        </h4>
                        <p className="main_para mt-2 mb-0">
                            1-8, Tempalli, Gannavaram, <br />
                            Krishna District, Andhra Pradesh – 521286
                        </p>
                    </div>
                </div>
                <div className="d-flex align-items-center mt-4">
                    <span className="bg-danger p-2 p-sm-3 rounded-circle">
                        <EmailIco/>
                    </span>
                    <div className='ms-5 ms-sm-1 ms-md-2 ms-lg-5'>
                        <h4 className="d-inline mb-2 text-danger">
                            Email
                        </h4>
                        <p className="main_para mt-2 mb-0">
                        contactus@l2global.in
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Contact;
