import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../src/assets/style/common.css";
import AboutUs from "./components/AboutUs/AboutUs";
import CareerPage from "./components/CareerPage/CareerPage";
// import LogoImg from "./assets/img/header/loader.webp";
import { UpArrowIcon } from "./components/common/Icons";
import ContactPage from "./components/ContactPage/ContactPage";
import { useScreenFixedProvider } from "./components/context/ScreenFixedProvider";
import ExpertisePage from "./components/ExpertisePage/ExpertisePage";
import IndustriesPage from "./components/IndustriesPage/IndustriesPage";
import Salesforce from "./components/SalesForcePage/Salesforce";
import ServicePage from "./components/ServicePage/ServicePage";
import Digital from "./components/Verticals/Digital";
import Fintech from "./components/Verticals/Fintech";
import Healthcare from "./components/Verticals/Healthcare";
import Hospitality from "./components/Verticals/Hospitality";
import Industry from "./components/Verticals/Industry";
import Retail from "./components/Verticals/Retail";
import Startups from "./components/Verticals/Startups";
import Home from "./views/Home";

function App() {
  const [loading, setLoading] = useState(false);
  const { showOverlay } = useScreenFixedProvider();
  const [showButton, setShowButton] = useState(false);

  AOS.init({ once: true });
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 3000);
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  
  useEffect(() => {
    if (showOverlay) {
      document.body.classList.add("setClass");
    } else {
      document.body.classList.remove("setClass");
    }
  }, [showOverlay]);
  return (
    <>
          <Router>
            <Switch>
                <Route path="/home">
                    <Home />
                </Route>
                <Route exact path="/services">
                    <ServicePage />
                </Route>
                <Route path="/contact">
                    <ContactPage />
                </Route>
                <Route path="/about">
                    <AboutUs />
                </Route>
                <Route path="/career">
                    <CareerPage />
                </Route>
                <Route exact path="/industries">
                    <IndustriesPage />
                </Route>
                <Route path="/expertise">
                    <ExpertisePage />
                </Route>
                <Route exact path="/services/salesforce">
                    <Salesforce />
                </Route>
                <Route exact path="/industries/digital-publishing">
                    <Digital />
                </Route>
                <Route exact path="/industries/fintech">
                    <Fintech />
                </Route>
                <Route exact path="/industries/healthcare">
                    <Healthcare />
                </Route>
                <Route exact path="/industries/hospitality-industry">
                    <Hospitality />
                </Route>
                <Route exact path="/industries/industry">
                    <Industry />
                </Route>
                <Route exact path="/industries/retail">
                    <Retail />
                </Route>
                <Route exact path="/industries/startups">
                    <Startups />
                </Route>
                <Route exact path="/">
                    {/* {loading ? ( */}
                        <Home />
                    {/* ) : (
                        <div className="h-100vh d-flex flex-column justify-content-center align-items-center">
                        <img className="w-25" src={LogoImg} />
                        </div>
                    )} */}
                </Route>
            </Switch>
          </Router>
      <>
        {showButton ? (
          <div
            className="topscroll d-flex justify-content-center align-items-center cursor-pointer"
            onClick={() => scrollToTop()}
          >
            <UpArrowIcon />
          </div>
        ) : (
          ""
        )}
      </>
    </>
  );
}

export default App;
