import React from "react";
import { Link } from "react-router-dom";
import AboutImg from "../../assets/img/about/AboutImg.webp";
import {
    ServicesCircleIcon,
    ServicesCloudIcon,
    ServicesHomeIcon,
    ServicesMobileIcon
} from "../common/Icons";

const About = () => {
  return (
    <>
      <section
        className="open_overlay_sections_none py-4 py-lg-5 mt-3"
      >
        <div className="container">
          <div className="text-center mb-2 mb-lg-5">
            <h2 className="d-inline main_heading px-3 py-2 bg_red mb-0">
              About Us
            </h2>
            <p className="text-center mt-4">
            Global Technology Ltd. is a Professional Design Agency to provide solutions. <Link className="links" to="/about">See more</Link>
            </p>
          </div>
          <div className="row justify-content-center justify-content-lg-between align-items-center">
            <div
              className="col-lg-6 mb-4 mb-lg-0"
              data-aos="fade-up-right"
              data-aos-duration="1500"
            >
              <div>
                <div className="d-flex mb-3 mb-lg-4 card_shadow p-3">
                  <div>
                    <span>
                      <ServicesCircleIcon />
                    </span>
                  </div>
                  <div>
                    <h2 className="main_para font-md fw-semibold">
                    About
                    </h2>
                    <p className="main_para mb-0">
                    Global Technologies is recognized for its excellence in key technologies, and efficient project management.Global Technologies builds on its experience and specialized skills to provide national scale IT solutions.
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-3 mb-lg-4 mt-5 card_shadow p-3">
                  <div>
                    <span>
                      <ServicesCloudIcon />
                    </span>
                  </div>
                  <div>
                    <h2 className="main_para font-md fw-semibold">
                    Mission
                    </h2>
                    <p className="main_para mb-0">
                    We are a company that has a sole objective to provide you one of the most efficient and result oriented web design and development services.
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-3 mb-lg-4 card_shadow p-3">
                  <div>
                    <span>
                      <ServicesMobileIcon />
                    </span>
                  </div>
                  <div className="ms-3">
                    <h2 className="main_para font-md fw-semibold">
                    Vision
                    </h2>
                    <p className="main_para mb-0">
                    Our vision has been reached to the level of success through the technical knowledge we possess and the tools we use. Besides, we also make perfect usage of the latest and most demanding software for creating an effectual and productive website for you.
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-3 mb-lg-4 card_shadow p-3">
                  <div>
                    <span>
                      <ServicesHomeIcon />
                    </span>
                  </div>
                  <div>
                    <h2 className="main_para font-md fw-semibold">
                    Deliver the Final Product
                    </h2>
                    <p className="main_para mb-0">
                    In the very last step, we make sure that we worked on all the feedback we received and try to give the experience to our all the clients.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-5"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            >
              <div className="">
                <img style={{width:'85%'}} src={AboutImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
