import React from "react";
import { Link } from "react-router-dom";
import FooterTopWave from "../../assets/img/footer/FooterTopWave.webp";
import {
    FooterFacebookIcon,
    FooterInstagramIcon,
    FooterLinkedInIcon, FooterTwitterIcon, FooterYoutubeIcon
} from "./Icons";
const Footer = () => {
  return (
    <>
      <div className="w-100 FromTopWave">
        <img className="w-100" src={FooterTopWave} alt="" />
      </div>
      <div className="footer-section   margin-top-minus-footer  open_overlay_sections_none">
        <div className="container">
          <div className="d-flex align-items-center mb-4 mb-lg-5 ">
            <a href="https://www.instagram.com/?hl=en" target="_blank" rel="noreferrer">
              <FooterInstagramIcon />
            </a>{" "}
            <a href="https://twitter.com/home" target="_blank" rel="noreferrer">
              <FooterTwitterIcon />
            </a>
            <a href="https://www.linkedin.com/company/l2-global-technologies-pvt-ltd/?viewAsMember=true" target="_blank" rel="noreferrer">
              <FooterLinkedInIcon />
            </a>{" "}
            <a href="https://www.facebook.com/people/L2-Global-Technologies-Pvt-Limited/100088223491869/" target="_blank" rel="noreferrer">
              <FooterFacebookIcon />
            </a>
            <a href="https://www.youtube.com/@l2globaltechnologies476" target="_blank" rel="noreferrer">
              <FooterYoutubeIcon />
            </a>
          </div>
          <div className="row justify-content-between">
            <div className="col-6 col-lg-auto mb-4">
              <div>
                <h4 className="font-lg fw-semibold mb-3 white">Company</h4>
                <ul className="list-unstyled">
                  <li>
                    <Link
                      to="/about"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      Team
                    </Link>
                  </li>
                  <li>
                    <a
                      href="/career"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      Career
                    </a>
                  </li>
                  <li>
                    <a
                      href="/contact"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-lg-auto mb-4">
              <div>
                <h4 className="font-lg fw-semibold mb-3 white">Expertise</h4>
                <ul className="list-unstyled">
                  <li>
                    <a
                      href="#Solution"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      Salesforce 
                    </a>
                  </li>
                  <li>
                    <a
                      href="#Global"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      Devops
                    </a>
                  </li>
                  <li>
                    <a
                      href="#Security"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      SAP
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      AWS
                    </a>
                  </li>
                  <li>
                    <a
                      href="#Legacy"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      Web Development
                    </a>
                  </li>
                  <li>
                    <a
                      href="#Quality"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      Testing and Development
                    </a>
                  </li>
                </ul>
              </div>
            </div>{" "}
            <div className="col-6 col-lg-auto mb-4">
              <div>
                <h4 className="font-lg fw-semibold mb-3 white">Services</h4>
                <ul className="list-unstyled">
                  <li>
                    <a
                      href="#"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      Salesforce
                    </a>
                  </li>
                  <li>
                    <a
                      href="#Cloud"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      Salesforce Implementation
                    </a>
                  </li>
                  <li>
                    <a
                      href="#Blockchain"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      Salesforce Development
                    </a>
                  </li>
                  <li>
                    <a
                      href="#Data"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      Salesforce Consultancy 
                    </a>
                  </li>
                  <li>
                    <a
                      href="#DevOps"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      DevOps
                    </a>
                  </li>
                  <li>
                    <a
                      href="#Data"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      SAP
                    </a>
                  </li>
                </ul>
              </div>
            </div>{" "}
            <div className="col-6 col-lg-auto mb-4">
              <div>
                <h4 className="font-lg fw-semibold mb-3 white">Industries</h4>
                <ul className="list-unstyled">
                  <li>
                    <a
                      href="/healthcare "
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      Healthcare
                    </a>
                  </li>
                  <li>
                    <a
                      href="/fintech"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      Finance
                    </a>
                  </li>
                  <li>
                    <a
                      href="/retail"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      Retail
                    </a>
                  </li>
                  <li>
                    <a
                      href="/media"
                      className="text-decoration-none font-normal opacity_07 white hoverline mb-2"
                    >
                      Media 
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <span className="footer-line d-inline-block mt-5"></span>
        <div className="copyright_text opacity_07">
          <p className="fw-normal font-normal white  text-center mb-0 py-3  ">
            © L2 Global Technologies pvt ltd.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
