import React from 'react';
import DigitalImg from '../../assets/img/verticals/digital-publishing.png';
import Footer from '../common/Footer';
import Header from '../common/Header';
import TopHeader from '../homepage/TopHeader';

const Digital = () => {
    return (
        <>
        <TopHeader />
        <Header />
        <section className="my-5 open_overlay_sections_none py-5">
            <div>
            <section className=" margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                <div id='salesforce-support' className="container">
                <div className="row justify-content-center justify-content-lg-between align-items-center">
                    <div
                        className="col-lg-6 text-center text-lg-start mt-4 mt-md-0"
                        data-aos="fade-up-right"
                        data-aos-duration="1500"
                        >
                        <img
                            className="w-75 "
                            src={DigitalImg}
                            alt=""
                        />
                    </div>
                    <div
                    className="col-lg-6  mt-5 mt-md-0"
                    data-aos="fade-up-left"
                    data-aos-duration="1500"
                    >
                        <div className="border-start border-4 border-danger ps-3 mb-4 mb-lg-4">
                            <h3 className="d-inline fw-light mb-0 text-normal">
                            Digital Platform 
                            </h3>
                            <h2 className=" text-danger">
                            Digital Publishing Platform 
                            </h2>
                            <p className="main_para mb-5">
                            iPublisher is a unique and user-friendly mobile and web content publishing present a niche content in a very intuitive ways, through various unique templates. It offers the freedom to create interesting stories and publish them at all target platforms in real-time.
                            </p>
                        </div>
                        <p className="main_para mt-4">
                        iPublisher is more than a CMS. This user-friendly, multi-device publishing platform has changed the scenario of content publishing. It offers the freedom to creatively present niche content in various intuitive ways through various unique templates comes with it. <br /> <br /> This also enables you to enrich the content by text, images, video, and adding interactivity. The content once published works beautifully across phones, tablets, and PCs. <br /><br /> To know more visit product website https://raj-portfolio-c46d6.web.app/
                        </p>
                    </div>
                </div>
                </div>
            </section>
            </div>
        </section>
        <Footer />
    </>
    );
};

export default Digital;