import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import EarthImg from "../../assets/img/hero/earth.svg";
import HeroImgGirl from "../../assets/img/hero/hero-imggirl.png";
import CarouselHero from "../../assets/img/hero/hero.webp";
import CarouselHero2 from "../../assets/img/hero/heroimg.webp";
import CarouselHero3 from "../../assets/img/hero/heroimg2.webp";
import CarouselHero4 from "../../assets/img/hero/heroimg3.webp";
import CarouselHero5 from "../../assets/img/hero/heroimg4.webp";
import CarouselHero6 from "../../assets/img/hero/heroimg5.png";
import ManImg from "../../assets/img/hero/man.png";
import TechImg from "../../assets/img/hero/tech.png";

const Hero = ({ history }) => {
  const [formValue, setFormValue] = useState({
    name: "",
    lastname: "",
    email: "",
  });
  const submitData = () => {
    if (formValue !== "") {
      setFormValue(formValue);
    }
  };
  return (
    <div className="hero-slider open_overlay_sections_none">
      <Carousel controls={false}>
        <Carousel.Item>
          <div className="position-relative">
            <img className="w-100 hero-img" src={CarouselHero6} alt="" />
            <div className="position-absolute top-0 h-100 w-100 d-flex flex-column  justify-content-center py-lg-0 py-md-5">
              <div className="container">
                <div className="row justify-content-lg-between justify-content-center">
                  <div className="col-xxl-5  my-auto col-lg-7 col-md-10  order-lg-1 order-2">
                    <h1 className="text-dark fw-extrabold pe-sm-5 me-sm- 5  text-lg-start text-center">
                    {/* Welcome to Global Technologies */}
                    GENERATE MORE ROI WITH L2 GLOBAL TECHNOLOGIES PVT LTD.
                    </h1>
                    <p className="text-dark pt-3 pe-sm-5  text-lg-start text-center">
                    {/* We are the L2 Global technologies Ltd. of IT-partnerships that are built on trust, innovation, and exceeding customers’ expectations. With our unparalleled web application development services, we promise to deliver you a website of superior performance that suits your organization’s specific needs. */}
                    Our Support Team helps create processes, customizations, and unique solutions that generate revenue faster and smarter. L2 Global Technologies pvt ltd Support can help your team streamline sales and customer support seamlessly, while scaling with your business.  
                    </p>
                    <div className=" text-lg-start text-center ">
                      <Link to="/services">
                        <button className="common_btn px-4 py-2 btn_hover">
                            Explore Now
                        </button>
                      </Link>
                      <Link to="/about">
                      <button className="learn-more-btn2 px-4 py-2 ms-3 btn_hover">
                        Learn More
                      </button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xxl-5 col-sm-5 col-7 order-lg-2 order-1">
                    <img
                    // moving_animation_img
                      className="hero_img"
                      src={HeroImgGirl}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="position-relative">
            <img className="w-100 hero-img" src={CarouselHero} alt="" />
            <div className="position-absolute top-0 h-100 w-100 d-flex flex-column justify-content-center ">
              <div className="container">
                <div className="row justify-content-lg-between justify-content-center">
                  <div className="col-xxl-6  my-auto col-lg-7 col-md-10  order-lg-1 order-2 pb-lg-0  pb-5">
                    <h1 className="text-white fw-extrabold pe-lg-5 me-lg-5  text-lg-start text-center">
                    IT Solutions in Diverse Culture.
                    </h1>
                    <p className="text-white pt-3 pe-lg-5  text-lg-start text-center">
                    Our business is helping you grow your business. We are your innovation success partner working will diverse culture.
                    </p>
                    <div className=" text-lg-start text-center ">
                     <Link to="/services">
                        <button className="common_btn px-4 py-2 btn_hover">
                            Explore Now
                        </button>
                      </Link>
                      <Link to="/about">
                      <button className="learn-more-btn2 px-4 py-2 ms-3 btn_hover">
                        Learn More
                      </button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-sm-5 col-7 order-lg-2 order-1">
                    <img
                    // moving_animation_img
                      className="hero_img"
                      src={EarthImg}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="position-relative">
            <img className="w-100 hero-img" src={CarouselHero2} alt="" />
            <div className="position-absolute top-0 h-100 w-100 d-flex flex-column justify-content-center">
              <div className="container">
                <div className="row justify-content-lg-between justify-content-center">
                  <div className="col-xxl-6  my-auto col-lg-7 col-md-10  order-lg-1 order-2 pb-lg-0  pb-5">
                    <h1 className="text-white fw-extrabold pe-lg-5 me-lg-5  text-lg-start text-center">
                    Integration Services
                    </h1>
                    <p className="text-white pt-3 pe-lg-5  text-lg-start text-center">
                    Every institution has multiple pieces of software and they should all be able to talk to one another.  We are experts at making this happen.  Chances are that we have already written an integration for software that you haven’t but, if we haven’t, we can!. We go above and beyond to make your business ready for industry 4.0 revulation.
                    </p>
                    <div className=" text-lg-start text-center ">
                     <Link to="/services">
                        <button className="common_btn px-4 py-2 btn_hover">
                            Explore Now
                        </button>
                      </Link>
                      <Link to="/about">
                      <button className="learn-more-btn2 px-4 py-2 ms-3 btn_hover">
                        Learn More
                      </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="position-relative">
            <img className="w-100 hero-img" src={CarouselHero3} alt="" />
            <div className="position-absolute top-0 h-100 w-100 d-flex flex-column justify-content-center">
              <div className="container">
                <div className="row justify-content-lg-between justify-content-center">
                  <div className="col-xxl-6  my-auto col-lg-7 col-md-10  order-lg-1 order-2 pb-lg-0  pb-5">
                    <h1 className="text-white fw-extrabold pe-lg-5 me-lg-5  text-sm-start text-center">
                    Super Transformation of L2 Global Technologies
                    </h1>
                    <p className="text-white pt-3 pe-lg-5  text-sm-start text-center">
                    L2 Global Technologies work with latest technologies to accelerate your digital transformation. we understands the modern enterprise and delivers best-of-breed technical solutions that increase efficiencies, drive costs out of systems and increase revenue.
                    </p>
                    <div className=" text-lg-start text-center ">
                     <Link to="/services">
                        <button className="common_btn px-4 py-2 btn_hover">
                            Explore Now
                        </button>
                      </Link>
                      <Link to="/about">
                      <button className="learn-more-btn2 px-4 py-2 ms-3 btn_hover">
                        Learn More
                      </button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-sm-6 col-7 order-lg-2 order-1">
                    <img
                    // moving_animation_img
                      className="hero_img"
                      src={TechImg}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="position-relative">
            <img
              className="hero-img object_position-left w-100"
              src={CarouselHero4}
              alt=""
            />
            <div className="position-absolute top-0 h-100 w-100 d-flex flex-column justify-content-center">
              <div className="container">
                <div className="row justify-content-end">
                  <div className="col-xxl-5 col-lg-7 col-md-10">
                    <button className="hero-btn px-4 py-2 my-3 fw-bold btn_hover">
                      DYNAMIC SLIDER
                    </button>
                    <p className="font-normal text-white">
                      from your WordPress posts and pages
                    </p>
                    <button className="hero-btn px-4 py-2  my-3 fw-bold btn_hover">
                      TIMELINE VIEW
                    </button>
                    <p className="font-normal text-white">for better timings</p>
                    <button className="hero-btn px-4 py-2  my-3 fw-bold btn_hover">
                      NEW TRANSITIONS
                    </button>
                    <p className="font-normal text-white">
                      slide,fade,scale,skew and rotate layers even in 3D
                    </p>
                    <button className="hero-btn px-4 py-2  my-3 fw-bold btn_hover">
                      INTERFACE REVAMP
                    </button>
                    <p className="font-normal text-white">
                      from your WordPress posts and pages
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="position-relative ">
            <img
              className="w-100 hero-img object_position-left"
              src={CarouselHero5}
              alt=""
            />
            <div className="position-absolute top-0 h-100 w-100 d-flex flex-column justify-content-center">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col d-flex justify-content-center">
                    <div className="hero-card py-sm-5 py-4 px-4">
                      <h1 className="fw-bold font-xxl text-center pb-4">
                        Subscribe!
                      </h1>
                      <div className="text-center">
                        <input
                          onChange={(e) =>
                            setFormValue({
                              ...formValue,
                              name: e.target.value,
                            })
                          }
                          className="input-field py-3 w-100 text-center "
                          placeholder=" Name"
                          type="text"
                        />
                      </div>
                      <div className="text-center my-4">
                        <input
                          className="input-field py-3 w-100 text-center "
                          placeholder=" Last Name"
                          type="text"
                          onChange={(e) =>
                            setFormValue({
                              ...formValue,
                              lastname: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="text-center my-4">
                        <input
                          className="input-field py-3 w-100 text-center "
                          placeholder=" E-Mail Address"
                          type="email"
                          onChange={(e) =>
                            setFormValue({
                              ...formValue,
                              email: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-check d-flex justify-content-center">
                        <input
                          className="form-check-input cursor-pointer me-3"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          I Agree With all Term And Conditions
                        </label>
                      </div>
                      <a href="https://layerslider.com/popups/webshopworks/">
                        <button
                          className="common_btn px-4 py-3 btn_hover w-100 mt-4"
                          onClick={() => submitData()}
                        >
                          Sign Up
                        </button>
                      </a>
                    </div>
                    <span className="d-md-inline d-none">
                      <img className="h-100" src={ManImg} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default withRouter(Hero);

