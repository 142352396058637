import React from 'react';
import StartupsImg from '../../assets/img/verticals/start-ups.png';
import Footer from '../common/Footer';
import Header from '../common/Header';
import TopHeader from '../homepage/TopHeader';

const Startups = () => {
    return (
        <>
            <TopHeader />
            <Header />
            <section className="my-5 open_overlay_sections_none py-5">
                <div>
                <section className=" margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                    <div id='salesforce-support' className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center">
                        <div
                            className="col-lg-6 text-center text-lg-start mt-4 mt-md-0"
                            data-aos="fade-up-right"
                            data-aos-duration="1500"
                            >
                            <img
                                className="w-75 "
                                src={StartupsImg}
                                alt=""
                            />
                        </div>
                        <div
                        className="col-lg-6  mt-5 mt-md-0"
                        data-aos="fade-up-left"
                        data-aos-duration="1500"
                        >
                            <div className="border-start border-4 border-danger ps-3 mb-4 mb-lg-4">
                                <h3 className="d-inline fw-light mb-0 text-normal">
                                Software Development
                                </h3>
                                <h2 className=" text-danger">
                                Company for Startups
                                </h2>
                                <p className="main_para mb-5">
                                Hire software and application developers for your startup idea
                                </p>
                            </div>
                            <p className="main_para mt-4">
                            Emorphis Technologies provides you with a wide range of services to help you get your startup idea off the ground. <br /><br /> We have successfully delivered various solutions in Fintech, IoT, healthcare, banking, retail, and eCommerce and also in the publishing and hospitality industry. <br /><br /> By outsourcing these services to Emorphis Technologies, you can focus on what you do best - running your business.
                            </p>
                        </div>
                    </div>
                    </div>
                </section>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Startups;