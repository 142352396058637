
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import LogoImage from "../../assets/img/header/logo.webp";
import SignIn from "../../pages/auth/SignIn";
import SignUp from "../../pages/auth/SignUp";
import { useScreenFixedProvider } from "../context/ScreenFixedProvider";


const Header = () => {
  const { showOverlay, setShowOverlay, setModalShow, setSignInModal } = useScreenFixedProvider();
  const [salesDropdown, setSalesDropdown] = useState(false);
  const [servicesDropdown, setServicesDropdown] = useState(false);
  const [saleforceDropdown, setSaleforceDropdown] = useState(false);
  const [companyDropdown, setCompanyDropdownDropdown] = useState(false);
  const [verticalsDropdown, setVerticalDropdown] = useState(false);

  useEffect(() =>{
      if(servicesDropdown) {
          setSaleforceDropdown(false);
          setCompanyDropdownDropdown(false);
          setVerticalDropdown(false)
        }
  },[servicesDropdown])
  
  const handleModal = () =>{
      setModalShow(true)
      setShowOverlay(false)
  }
  const handleSignIn = () =>{
    setSignInModal(true)
      setShowOverlay(false)
  }
  return (
    <>
      <section className="d-flex w-100 navbar-wrapper  py-sm-3 py-2">
        <div className="d-lg-flex d-none w-100">
          <div className="d-flex container justify-content-between align-items-center">
            <div>
              <Link to="/"><img src={LogoImage} alt="logo-image" /></Link>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              {/* <span className="d-inline-block  py-1 px-2 mx-2">
                <li className=" nav-list-items text-dark  d-inline-block ">
                  <Link className="text-black" to="/">
                    Home
                  </Link>
                </li>
              </span> */}
              {/* <span className="d-inline-block  py-1 px-2 mx-2">
                <li className=" ml-0 text-dark d-inline-block ">
                  <Dropdown>
                    <Dropdown.Toggle className="nav-list-items">
                    <a className="text-black">
                    Industries
                    </a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="py-3 medium_menu">
                      <Dropdown.Item className="pb-2 nav-list-items">
                        <Link className="text-black" to="/industries">
                        Page 1
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="pb-2 nav-list-items">
                        <Link className="text-black" to="/industries">
                        Page 2
                        </Link></Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </span> */}
              <span className="d-inline-block  py-1 px-2 mx-2">
                <li className=" ml-0 text-dark d-inline-block ">
                    <Dropdown>
                        <Dropdown.Toggle className="nav-list-items">
                        <a className="text-black">
                        Industries
                        </a>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="py-3 medium_menu">
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/industries/healthcare">
                            Healthcare
                            </Link></Dropdown.Item>
                          <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/industries/hospitality-industry">
                            Hospitality Industry
                            </Link></Dropdown.Item>
                           <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/industries/fintech">
                            Fintech
                            </Link></Dropdown.Item>
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/industries/retail">
                            Retail
                            </Link></Dropdown.Item>
                        {/*<Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/industries/startups">
                            Startups
                            </Link></Dropdown.Item>
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/industries/industry">
                            Industry 4.0
                            </Link></Dropdown.Item>
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/industries/digital-publishing">
                            Disital Publishing
                            </Link></Dropdown.Item>
                         <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/industries/industry">
                            Industries
                            </Link></Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
              </span> 
              <span style={{position:'relative'}} className="d-inline-block py-1 px-2 mx-2">
                <li className=" ml-0 text-dark d-inline-block ">
                <Dropdown>
                    <Dropdown.Toggle className="nav-list-items">
                    <a className="text-black">
                    Services
                    </a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="py-3 medium_menu">
                      <Dropdown.Item onMouseEnter={() => setSalesDropdown(true)} onMouseLeave={() => setSalesDropdown(false)} style={{position:'relative',}} className="pb-2 nav-list-items">
                        <a className="text-black" >
                        Salesforce &nbsp; &gt;&gt;
                        </a>
                       
                      </Dropdown.Item>
                      <Dropdown.Item className="pb-2 nav-list-items">
                        <Link className="text-black" to="/">
                        SAP 
                        </Link></Dropdown.Item>
                      <Dropdown.Item className="pb-2 nav-list-items">
                        <Link className="text-black" to="/">
                        DevOPs
                        </Link></Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <div onMouseEnter={() => setSalesDropdown(true)} onMouseLeave={() => setSalesDropdown(false)} className={`py-3 dropdown-second-layer ${salesDropdown ? 'd-block' : 'd-none' }`} >
                        <span onClick={() => setSalesDropdown(false)} className="d-block salse-menu">
                            <li className=" nav-list-items text-dark d-block ">
                                <Link smooth className="text-black text-nowrap" to="/services/salesforce#salesforce-consulting">
                                Salesforce Consulting 
                                </Link>
                            </li>
                        </span>
                        <span onClick={() => setSalesDropdown(false)} className="d-block salse-menu">
                            <li className=" nav-list-items text-dark  d-block ">
                                <Link smooth className="text-black text-nowrap" to="/services/salesforce#salesforce-implementation">
                                Salesforce Implementation 
                                </Link>
                            </li>
                        </span>
                        <span onClick={() => setSalesDropdown(false)} className="d-block salse-menu">
                            <li className=" nav-list-items text-dark  d-block ">
                                <Link smooth className="text-black text-nowrap" to="/services/salesforce#salesforce-support">
                                Salesforce Support
                                </Link>
                            </li>
                        </span>
                    </div>
              </span>
              <span className="d-inline-block  py-1 px-2 mx-2">
                <li className="nav-list-items text-dark  d-inline-block ">
                      <Link
                        className="text-black"
                        to="/expertise"
                      >
                        Expertise
                      </Link>
                </li>
              </span>

              {/* <span className="d-inline-block  py-1 px-2 mx-2">
                <li className=" ml-0 text-dark d-inline-block ">
                    <Dropdown>
                        <Dropdown.Toggle className="nav-list-items">
                        <a className="text-black">
                        Expertise
                        </a>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="py-3 medium_menu">
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/career">
                            Enterprise Mobility
                            </Link></Dropdown.Item>
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/about">
                            Cloud Computing
                            </Link></Dropdown.Item>
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/contact">
                            Inter of Things
                            </Link></Dropdown.Item>
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/contact">
                            Backend Development
                            </Link></Dropdown.Item>
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/contact">
                            Big Data & Analytics
                            </Link></Dropdown.Item>
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/salesforce">
                            Saleforce
                            </Link></Dropdown.Item>
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/contact">
                            Blockchain Application
                            </Link></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
                
              </span> */}
              {/* <span className="d-inline-block  py-1 px-2 mx-2">
                <li className=" ml-0 text-dark d-inline-block ">
                    <Dropdown>
                        <Dropdown.Toggle className="nav-list-items">
                        <a className="text-black">
                        Verticals
                        </a>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="py-3 medium_menu">
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/career">
                            Healthcare
                            </Link></Dropdown.Item>
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/about">
                            Startups
                            </Link></Dropdown.Item>
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/contact">
                            Industry 4.0
                            </Link></Dropdown.Item>
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/contact">
                            Fintech
                            </Link></Dropdown.Item>
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/contact">
                            Retail
                            </Link></Dropdown.Item>
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/contact">
                            Disital Publishing
                            </Link></Dropdown.Item>
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/contact">
                            Hospitality Industry
                            </Link></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
              </span> */}
              <span className="d-inline-block  py-1 px-2 mx-2">
                <li className=" ml-0 text-dark d-inline-block ">
                    <Dropdown>
                        <Dropdown.Toggle className="nav-list-items">
                        <a className="text-black">
                        Company
                        </a>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="py-3 medium_menu">
                        {/* <Dropdown.Item onMouseEnter={() => setSalesDropdown(true)} onMouseLeave={() => setSalesDropdown(false)} style={{position:'relative',}} className="pb-2 nav-list-items">
                            <a className="text-black" >
                            Salesforce &nbsp; &gt;&gt;
                            </a>
                        
                        </Dropdown.Item> */}
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/about">
                            About Us
                            </Link></Dropdown.Item>
                          <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/career">
                            Career
                            </Link></Dropdown.Item>
                        <Dropdown.Item className="pb-2 nav-list-items">
                            <Link className="text-black" to="/contact">
                            Contact Us
                            </Link></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
                {/* <li className=" nav-list-items text-dark  d-inline-block ">
                  <Link className="  text-black" to="/career">
                    Career
                  </Link>
                </li> */}
              </span>

              {/* <span className="d-inline-block  py-1 px-2 mx-2">
                <li className=" nav-list-items text-dark  d-inline-block ">
                  <Link className="  text-black" to="/about">
                    About
                  </Link>
                </li>
              </span> */}
              {/* <span className="d-inline-block  py-1 px-2 mx-2">
                <li className=" nav-list-items text-dark  d-inline-block ">
                  <Link className="  text-black" to="/contact">
                    {" "}
                    Contact Us
                  </Link>
                </li>
              </span> */}
            </div>
            {/* <div>
              <button onClick={handleModal} className="common_btn border-0 py-2 px-4 mx-2 btn_hover">
                Sign Up
              </button>
              <button onClick={handleSignIn} className="cmn_desk_btn_border border-0 py-2 px-4 mx-2 btn_hover">
                Sign In
              </button>
            </div> */}
            <div></div>
          </div>
        </div>

        {/* OVERLAY HERE  THIS CODE RUN BEFORE 992PX*/}
        <div className="d-lg-none d-flex w-100">
          <div className={`${showOverlay ? "overlay-active" : ""} overlay-nav`}>
          {/* align-items-center */}
            <div className="nav-bg-light h-screen d-flex justify-content-center pt-5 overflow-auto">
              <div className="d-flex flex-column text-center ">
                <ul className="p-0">
                  {/* <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                    <span
                      className="py-2 py-sm-4 d-inline-block cursor-pointer"
                      onClick={() => setShowOverlay(false)}
                    >
                      <Link
                        to="/"
                        className="fs-2 hoverline text-white text-decoration-none"
                      >
                        Home
                      </Link>
                    </span>
                  </li> */}
                  {/* <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase">
                    <span
                      className="py-2 py-sm-4 d-inline-block cursor-pointer"
                      onClick={() => setShowOverlay(false)}
                    >
                      <Link
                        to="/industries"
                        className="fs-2 hoverline text-white text-decoration-none"
                      >
                        Industries
                      </Link>
                    </span>
                  </li> */}
                  <li className="nav-item btn-img mt-3 mt-lg-1 transition-0_3sEase">
                    <span
                      className="py-2 py-sm-4 d-inline-block cursor-pointer"
                    //   onClick={() => setShowOverlay(false)}
                    onClick={() => setVerticalDropdown(!verticalsDropdown)}
                    >
                      <a
                        className="fs-2 hoverline text-white text-decoration-none d-flex align-items-center"
                      >
                        Industries &nbsp;
                        {verticalsDropdown?
                         <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-caret-down-fill text-light" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> 
                        :
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className={`bi bi-caret-down-fill text-light`} viewBox="0 0 16 16">
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                        </svg>
                        }
                      </a>
                    </span>
                  </li>

                  {/* hover services */}
                  {verticalsDropdown &&
                  <div style={{marginBottom:'2.5rem'}} className="bg-light pt-4 pb-2 px-2">
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/industries/healthcare"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                Healthcare
                            </Link>
                            </span>
                        </li>
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/industries/startups"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                Startups
                            </Link>
                            </span>
                        </li>
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/industries/industry"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                Industry 4.0
                            </Link>
                            </span>
                        </li>
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/industries/fintech"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                Fintech
                            </Link>
                            </span>
                        </li>
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/industries/retail"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                Retail
                            </Link>
                            </span>
                        </li>
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/industries/digital-publishing"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                Disital Publishing
                            </Link>
                            </span>
                        </li>
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/industries/hospitality-industry"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                Hospitality Industry
                            </Link>
                            </span>
                        </li>
                  </div>
                  }
                  <li className="nav-item btn-img mt-3 mt-lg-1 transition-0_3sEase">
                    <span
                      className="py-2 py-sm-4 d-inline-block cursor-pointer"
                    //   onClick={() => setShowOverlay(false)}
                    onClick={() => setServicesDropdown(!servicesDropdown)}
                    >
                      <a
                        className="fs-2 hoverline text-white text-decoration-none d-flex align-items-center"
                      >
                        Services &nbsp;
                        {servicesDropdown?
                         <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-caret-down-fill text-light" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> 
                        :
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className={`bi bi-caret-down-fill text-light`} viewBox="0 0 16 16">
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                        </svg>
                        }
                      </a>
                    </span>
                  </li>

                  {/* hover services */}
                  {servicesDropdown &&
                  <div style={{marginBottom:'2.5rem'}} className="bg-light pt-4 pb-2">
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            // onClick={() => setShowOverlay(false)}
                            onClick={() => setSaleforceDropdown(!saleforceDropdown)}
                            >
                            <a
                                // to="/"
                                className="fs-2 hoverline text-dark text-decoration-none d-flex align-items-center"
                            >
                                Salesforce &nbsp;
                                {saleforceDropdown?
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-caret-down-fill text-dark" viewBox="0 0 16 16">
                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                </svg> 
                                :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className={`bi bi-caret-down-fill text-dark`} viewBox="0 0 16 16">
                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                                </svg>
                                }
                            </a>
                            </span>
                        </li>
                        {saleforceDropdown &&
                        <div className="pt-4 mb-5 px-2 mx-2 salesforce-dropdown">
                            <li className="nav-item btn-img mt-3 mt-lg-1 transition-0_3sEase overflow-hidden">
                                <span
                                className="py-1 py-sm-2 d-inline-block cursor-pointer"
                                onClick={() => setShowOverlay(false)}
                                >
                                <Link
                                    smooth
                                    to="/services/salesforce#salesforce-consulting"
                                    className="fs-2 hoverline text-dark text-decoration-none"
                                >
                                    Salesforce Consulting
                                </Link>
                                </span>
                            </li>
                            <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                                <span
                                className="py-1 py-sm-2 d-inline-block cursor-pointer"
                                onClick={() => setShowOverlay(false)}
                                >
                                <Link
                                    smooth
                                    to="/services/salesforce#salesforce-implementation"
                                    className="fs-2 hoverline text-dark text-decoration-none"
                                >
                                    Salesforce Implementation
                                </Link>
                                </span>
                            </li>
                            <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                                <span
                                className="py-1 py-sm-2 d-inline-block cursor-pointer"
                                onClick={() => setShowOverlay(false)}
                                >
                                <Link
                                    smooth
                                    to="/services/salesforce#salesforce-support"
                                    className="fs-2 hoverline text-dark text-decoration-none"
                                >
                                    Salesforce Support
                                </Link>
                                </span>
                            </li>
                        </div>
                        }
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                SAP
                            </Link>
                            </span>
                        </li>
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                DevOPs
                            </Link>
                            </span>
                        </li>
                  </div>
                  }
                  <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                    <span
                      className="py-2 py-sm-4 d-inline-block cursor-pointer"
                      onClick={() => setShowOverlay(false)}
                    >
                      <Link
                        to="/expertise"
                        className="fs-2 hoverline text-white text-decoration-none"
                      >
                        Expertise
                      </Link>
                    </span>
                  </li>
                  {/* <li className="nav-item btn-img mt-3 mt-lg-1 transition-0_3sEase">
                    <span
                      className="py-2 py-sm-4 d-inline-block cursor-pointer"
                    //   onClick={() => setShowOverlay(false)}
                    onClick={() => setExpertiseDropdown(!expertiseDropdown)}
                    >
                      <a
                        className="fs-2 hoverline text-white text-decoration-none d-flex align-items-center"
                      >
                        Expertise &nbsp;
                        {expertiseDropdown?
                         <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-caret-down-fill text-light" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> 
                        :
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className={`bi bi-caret-down-fill text-light`} viewBox="0 0 16 16">
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                        </svg>
                        }
                      </a>
                    </span>
                  </li>

                  
                  {expertiseDropdown &&
                  <div style={{marginBottom:'2.5rem'}} className="bg-light pt-4 pb-2 px-2">
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/about"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                Enterprise Mobility
                            </Link>
                            </span>
                        </li>
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/career"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                Cloud Computing
                            </Link>
                            </span>
                        </li>
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/contact"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                Inter of Things
                            </Link>
                            </span>
                        </li>
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/contact"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                Backend Development
                            </Link>
                            </span>
                        </li>
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/contact"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                Big Data & Analytics
                            </Link>
                            </span>
                        </li>
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/contact"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                Saleforce
                            </Link>
                            </span>
                        </li>
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/contact"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                Blockchain Application
                            </Link>
                            </span>
                        </li>
                  </div>
                  }
                   */}
                  <li className="nav-item btn-img mt-3 mt-lg-1 transition-0_3sEase">
                    <span
                      className="py-2 py-sm-4 d-inline-block cursor-pointer"
                    //   onClick={() => setShowOverlay(false)}
                    onClick={() => setCompanyDropdownDropdown(!companyDropdown)}
                    >
                      <a
                        className="fs-2 hoverline text-white text-decoration-none d-flex align-items-center"
                      >
                        Company &nbsp;
                        {companyDropdown?
                         <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-caret-down-fill text-light" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> 
                        :
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className={`bi bi-caret-down-fill text-light`} viewBox="0 0 16 16">
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                        </svg>
                        }
                      </a>
                    </span>
                  </li>

                  {/* hover services */}
                  {companyDropdown &&
                  <div style={{marginBottom:'2.5rem'}} className="bg-light pt-4 pb-2">
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/about"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                About
                            </Link>
                            </span>
                        </li>
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/career"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                Career
                            </Link>
                            </span>
                        </li>
                        <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                            <span
                            className="py-1 py-sm-2 d-inline-block cursor-pointer"
                            onClick={() => setShowOverlay(false)}
                            >
                            <Link
                                to="/contact"
                                className="fs-2 hoverline text-dark text-decoration-none"
                            >
                                Contact Us
                            </Link>
                            </span>
                        </li>
                  </div>
                  }
                  {/* <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase">
                    <span
                      className="py-2 py-sm-4 d-inline-block cursor-pointer"
                      onClick={() => setShowOverlay(false)}
                    >
                      <Link
                        to="/about"
                        className="fs-2 hoverline text-white text-decoration-none"
                      >
                        About
                      </Link>
                    </span>
                  </li> */}
                  {/* <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase">
                    <span
                      className="py-2 py-sm-4 d-inline-block cursor-pointer"
                      onClick={() => setShowOverlay(false)}
                    >
                      <Link
                        to="/career"
                        className="fs-2 hoverline text-white text-decoration-none"
                      >
                        Career
                      </Link>
                    </span>
                  </li>
                  <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase">
                    <span
                      className="py-2 py-sm-4 d-inline-block cursor-pointer"
                      onClick={() => setShowOverlay(false)}
                    >
                      <Link
                        to="/contact"
                        className="fs-2 hoverline text-white text-decoration-none"
                      >
                        Contact Us
                      </Link>
                    </span>
                  </li> */}
                  <li className="nav-item btn-img mt-4 mt-lg-1  transition-0_3sEase">
                    <div>
                      <button onClick={handleModal} className="mobile_sign_up border-0 py-2 px-4 mx-2 btn_hover">
                        Sign Up
                      </button>
                      {/* <Login/> */}
                      <button onClick={handleSignIn} className="cmn_btn_border border-0 py-2 px-4 mx-2 btn_hover">
                        Sign In
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <nav className="navbar h-86 navbar-expand-lg navbar-light p-3 w-100">
            <div className="d-flex justify-content-between w-100 align-items-center">
            <Link to="/"><img src={LogoImage} alt="logo-image" /></Link>
              <div
                className={`${
                  showOverlay ? "animate" : ""
                } position-relative navbarwrapper  hamburger-icon d-flex flex-column d-lg-none`}
                onClick={() => setShowOverlay(!showOverlay)}
              >
                <span className="first d-inline-block"></span>
                <span className="second d-inline-block"></span>
                <span className="third d-inline-block"></span>
              </div>
            </div>
          </nav>
        </div>
      </section>
      <SignUp />
      <SignIn />
    </>
  );
};

export default Header;

function PhoneDropDown () {
    return (
        <div className="bg-success">
            <p>text yellow</p>
        </div>
    )
}

function CaretDownIcon () {
    return(
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-caret-down-fill text-light" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
            </svg> 
        </>
    )
}

function CaretUpIcon () {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className={`bi bi-caret-down-fill text-light`} viewBox="0 0 16 16">
                <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
            </svg> 
        </>
    )
}
