import React from "react";
import mission from "../../assets/img/cards/mission.svg";
import strategy from "../../assets/img/cards/strategy.svg";
import values from "../../assets/img/cards/values.svg";
import vision from "../../assets/img/cards/vision.svg";
const Cards = () => {

  return (
    <>
      <section className="open_overlay_sections_none   pb-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-4 col-xxl-3 mb-4">
              <div className="card_box p-3 text-center">
                <img className="mb-2" src={vision} alt="" />
                <h2 className="font-xl fw-semibold black">Digital Buying</h2>
                <p className="font-normal opacity_07  black">
                If you are looking for a better return on your ad spend then Technologies is the place to go. Technology's team of expert buying professionals ensure creating a spot-on media buying strategy, putting it into execution and finally achieving cost optimization for better ad performance and ROI for your brands.
                </p>
                <div className="py-3 learn_btns">
                  <a
                    href=""
                    className="learn_btn  px-3 py-2 black text-decoration-none font-normal"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xxl-3 mb-4">
              <div className="card_box p-3 text-center">
                <img className="mb-2" src={strategy} alt="" />
                <h2 className="font-xl fw-semibold black">Data Analytics</h2>
                <p className="font-normal opacity_07  black">
                Data is the oil of the digital economy, the more you mine the more you can fuel your brand. With enormous amount of user data developed from your brand’s presence in online and offliine, we can turn them into actionable insights. With proper analytics and reporting generated from Global Technologies team you can get an in depth understanding of how your brand is faring as well as reaching the perfect audience who feel more affinity to your brand.
                </p>
                <div className="py-3 learn_btns">
                  <a
                    href=""
                    className="learn_btn  px-3 py-2 black text-decoration-none font-normal"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xxl-3 mb-4">
              <div className="card_box p-3 text-center">
                <img className="mb-2" src={values} alt="" />
                <h2 className="font-xl fw-semibold black">Web Experience</h2>
                <p className="font-normal opacity_07  black">
                Technologies don't just design website also gives it life. We have a vast experience of creating interactive ideas & implementing them in website form. We believe a website is where you can create your brand equity. Also, with the rise of mobile traffic to web platforms we have mastered the art of mobile device optimization and as a result enhancing the user experience.
                </p>
                <div className="py-3 learn_btns">
                  <a
                    href=""
                    className="learn_btn  px-3 py-2 black text-decoration-none font-normal"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xxl-3 mb-4">
              <div className="card_box p-3 text-center">
                <img className="mb-2" src={mission} alt="" />
                <h2 className="font-xl fw-semibold black">Enterprise Software</h2>
                <p className="font-normal opacity_07  black">
                Technologies is a highly capable provider of Enterprise Software Development services that include specifications, database design with software component, and service-oriented architecture.
                </p>
                <div className="py-3 learn_btns">
                  <a
                    href=""
                    className="learn_btn  px-3 py-2 black text-decoration-none font-normal"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cards;
