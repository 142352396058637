import CardImg1 from "../../assets/img/cards/cardimg1.webp";
import CardImg2 from "../../assets/img/cards/cardimg2.webp";
import CardImg3 from "../../assets/img/cards/cardimg3.webp";
import CardImg4 from "../../assets/img/cards/cardimg4.webp";
import ManImg from "../../assets/img/feedback/man.webp";
import StarImg from "../../assets/img/feedback/star.webp";
import CarouselHero from "../../assets/img/hero/hero.webp";
import CarouselHero2 from "../../assets/img/hero/heroimg.webp";
import CarouselHero3 from "../../assets/img/hero/heroimg2.webp";
import CarouselHero4 from "../../assets/img/hero/heroimg3.webp";
import CarouselHero5 from "../../assets/img/hero/heroimg4.webp";
import TeamImg1 from "../../assets/img/team/Team1.webp";
import TeamImg2 from "../../assets/img/team/Team2.webp";
import TeamImg3 from "../../assets/img/team/Team3.webp";
import TeamImg4 from "../../assets/img/team/Team4.webp";
import TeamCardImg1 from "../../assets/img/teamcards/teamcard1.png";
import TeamCardImg2 from "../../assets/img/teamcards/teamcard2.png";
export const TeamData = [
  {
    img: TeamImg1,
    heading: "Maria Korsgaard ",
    text: "Finicial Advisor",
  },
  {
    img: TeamImg2,
    heading: "Jaydon Bator",
    text: "CEO",
  },
  {
    img: TeamImg3,
    heading: "Leo Workman",
    text: "Co-Founder",
  },
  {
    img: TeamImg4,
    heading: "Lincoln Philips",
    text: "Invester",
  },
];
export const HeroData = [
  {
    img: CarouselHero,
    heading: "Welcome To l2 Global Technologies",
    text: "Urna, sem amet neque ac. In tellus morbi facilisi est tortor ultrices. Suspendisse eget consequat mi pulvinar. ",
  },
  {
    img: CarouselHero2,
    heading: "Let your mind  Explore new worlds",
    text: "It gives you the freedom to do it all, Games,entertainment,live events, fitness and more. ",
  },
  {
    img: CarouselHero3,
    heading: "Nulla ipsum bibendum leo urna.",
    text: "Pellentesque at massa, elit suscipit etiam neque volutpat quis pellentesque. Sagittis, viverra enim est quis. ",
  },
  {
    img: CarouselHero4,
    heading: "Nulla ipsum bibendum leo urna. Id.",
    text: "Pellentesque at massa, elit suscipit etiam neque volutpat quis pellentesque. Sagittis, viverra enim est quis. ",
  },
  {
    img: CarouselHero5,
    heading: "Nulla ipsum bibendum leo urna. Id.",
    text: "Pellentesque at massa, elit suscipit etiam neque volutpat quis pellentesque. Sagittis, viverra enim est quis. ",
  },
];

export const CardsImgData = [
  {
    img: CardImg1,
    heading: "VISION",
    text: "Convallis suscipit quam justo, pulvinar lobortis. ",
  },
  {
    img: CardImg2,
    heading: "STRATEGY",
    text: "Convallis suscipit quam justo, pulvinar lobortis.  ",
  },
  {
    img: CardImg3,
    heading: "VALUES",
    text: "Convallis suscipit quam justo, pulvinar lobortis.  ",
  },
  {
    img: CardImg4,
    heading: "MISSION",
    text: "Convallis suscipit quam justo, pulvinar lobortis. ",
  },
  {
    img: CardImg1,
    heading: "VISION",
    text: "Convallis suscipit quam justo, pulvinar lobortis. ",
  },
  {
    img: CardImg2,
    heading: "STRATEGY",
    text: "Convallis suscipit quam justo, pulvinar lobortis.  ",
  },
];
export const FeedbackSliderData = [
  {
    heading: "Customers And Feedback",
    img: ManImg,
    starimg: StarImg,
    para: "Pellentesque lacus, ut lorem eu quis maecenas nibh. Donec netus sit tortor, ac. Non magna ut convallis aliquam cursus. Maecenas viverra in sed elit m",
    smallHeading: "Cordkf Advsww",
    text: "FINICIAL ANALYST",
  },
  {
    heading: "Customers And Feedback",
    img: ManImg,
    starimg: StarImg,
    para: "Pellentesque lacus, ut lorem eu quis maecenas nibh. Donec netus sit tortor, ac. Non magna ut convallis aliquam cursus. Maecenas viverra in sed elit m",
    smallHeading: "Cordkf Advsww",
    text: "FINICIAL ANALYST",
  },
  {
    heading: "Customers And Feedback",
    img: ManImg,
    starimg: StarImg,
    para: "Pellentesque lacus, ut lorem eu quis maecenas nibh. Donec netus sit tortor, ac. Non magna ut convallis aliquam cursus. Maecenas viverra in sed elit m",
    smallHeading: "Cordkf Advsww",
    text: "FINICIAL ANALYST",
  },
  {
    heading: "Customers And Feedback",
    img: ManImg,
    starimg: StarImg,
    para: "Pellentesque lacus, ut lorem eu quis maecenas nibh. Donec netus sit tortor, ac. Non magna ut convallis aliquam cursus. Maecenas viverra in sed elit m",
    smallHeading: "Cordkf Advsww",
    text: "FINICIAL ANALYST",
  },
];
export const TeamCardsData = [
  {
    img: TeamCardImg1,
    heading: "Maria Korsgaard",
    text: "Finicial Advisor",
  },
  {
    img: TeamCardImg2,
    heading: "Maria Korsgaard",
    text: "Finicial Advisor",
  },
  {
    img: TeamCardImg2,
    heading: "Lincoln Philips",
    text: "Invester",
  },
];


export const opportunityCard =[
    {
        country:'London, UK',
        title:'UI / UX Designer',
        subtitle:'Fulltime, Onsite',
        text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit.  Ut elit tellus, luctus nec ullamcorper mattis.'
    },
    {
        country:'London, UK',
        title:'UI / UX Designer',
        subtitle:'Fulltime, Onsite',
        text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit.  Ut elit tellus, luctus nec ullamcorper mattis.'
    },
    {
        country:'London, UK',
        title:'UI / UX Designer',
        subtitle:'Fulltime, Onsite',
        text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit.  Ut elit tellus, luctus nec ullamcorper mattis.'
    },
    {
        country:'London, UK',
        title:'UI / UX Designer',
        subtitle:'Fulltime, Onsite',
        text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit.  Ut elit tellus, luctus nec ullamcorper mattis.'
    },
    {
        country:'London, UK',
        title:'UI / UX Designer',
        subtitle:'Fulltime, Onsite',
        text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit.  Ut elit tellus, luctus nec ullamcorper mattis.'
    },
    {
        country:'London, UK',
        title:'UI / UX Designer',
        subtitle:'Fulltime, Onsite',
        text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit.  Ut elit tellus, luctus nec ullamcorper mattis.'
    },
]

export const workFlowCard =[
    {
        title:"Planning",
        text:"We frame a strategical plan to keep the things managed and logically organized. We create a brief Project plan, SRS (Software Requirements Specification) and other important documentations. It majorly comprises of the procedure we will follow, resources assigned for the successful accomplishment of the task.",
    },
    {
        title:"Implementation",
        text:"Our dedicated and skilled Teams execute the test cases to check the functionality and behavior of your software or website. This process is intended to check whether its current functionality meets the expected functionality. ",
    },
    {
        title:"Test Reporting",
        text:"After the successful implementation of all Design/Devlopment, we proceed towards test reporting. Our testers make metrics and generate final reports of their test attempts. Almost 80-90% errors have been diagnosed in this phase. The accuracy of this decides whether the application is ready to be delivered to be exercised by the end-users.",
    },
    {
        title:"Result Analysis",
        text:"We do a detailed analysis on the test results. The potential bugs are resolved according to their priorities and the bug-free application is set to be delivered. This phase includes a strong coordination between the development team and the testing team which explicitly you can find here at Global Technologies Ltd.",
    },
    {
        title:"Test Closure",
        text:"Testing seems to be a never-ending process as we can never satisfy ourselves fully. Every time, we all put our best efforts to expand and enhancing our services. But, in order to make your websites live to get exposure from end-users, we have to wind-up this process. This phase signifies that the test has met its exit criteria.",
    },
    {
        title:"Deliver the Final Product",
        text:"Now, after the successful completion of all these phases, you can own a bug-free application and make your end-users contented with a flawless digital experience. In the very last step, we make sure that we worked on all the feedback we received and try to give the experience to our all the clients. ",
    },
]