import React from 'react';
import Footer from '../common/Footer';
import Header from '../common/Header';
import TopHeader from '../homepage/TopHeader';
import FintechImg from '../../assets/img/verticals/fintech.png';

const Fintech = () => {
    return (
        <>
            <TopHeader />
            <Header />
            <section className="my-5 open_overlay_sections_none py-5">
                <div>
                <section className=" margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                    <div id='salesforce-support' className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center">
                        <div
                            className="col-lg-6 text-center text-lg-start mt-4 mt-md-0"
                            data-aos="fade-up-right"
                            data-aos-duration="1500"
                            >
                            <img
                                className="w-75 "
                                src={FintechImg}
                                alt=""
                            />
                        </div>
                        <div
                        className="col-lg-6  mt-5 mt-md-0"
                        data-aos="fade-up-left"
                        data-aos-duration="1500"
                        >
                            <div className="border-start border-4 border-danger ps-3 mb-4 mb-lg-4">
                                <h3 className="d-inline fw-light mb-0 text-normal">
                                Fintech Developers
                                </h3>
                                <h2 className=" text-danger">
                                Hire Fintech App Developers
                                </h2>
                                <p className="main_para mb-5">
                                We offer Fintech Application Development Services for Startup and Enterprises
                                </p>
                            </div>
                            <p className="main_para mt-4">
                            We strive to deliver a crisp UX interface that serves the best user experience for your customer. Our fintech app developers work on the latest technologies and follow best practices in the industry to create seamless, attractive, and viable fintech applications. As your FinTech Software Development Company, or FinTech App Development Partner, we can help you in planning how to start with your FinTech app ideas, developing the FinTech app prototype, and building your FinTech App. 
                            </p>
                        </div>
                    </div>
                    </div>
                </section>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Fintech;