import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CareerImg from '../../assets/img/career/career.jpg';
import FromBottomWave from '../../assets/img/form/FromBottomWave.webp';
import FromTopWave from '../../assets/img/form/FromTopWave.webp';
import BgImg from '../../assets/img/hero/heroimg2.webp';
import { autoScrollUp } from '../common/autoScrollUp';
import { opportunityCard } from '../common/Content';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { CareerLogo_1, CareerLogo_2, CareerLogo_3 } from '../common/Icons';
import TopHeader from '../homepage/TopHeader';
import styles from '../ServicePage/ServicePage.module.css';

const CareerPage = () => {
    useEffect(() => {
        autoScrollUp()
    },[])
    return (
        <div>
            <TopHeader />
            <Header />
            <section className="position-relative">
                <img className={`w-100 ${styles.service_img}`} src={BgImg} alt="" />
                <div className="position-absolute top-0 h-100 w-100 d-flex flex-column justify-content-center">
                <div className="container">
                    <div className="row justify-content-lg-between justify-content-center">
                        <h1 className="text-white fw-extrabold pe-lg-5 me-lg-5 text-center">
                            Career
                        </h1>
                    </div>
                </div>
                </div>
            </section>
            <section className="my-5 open_overlay_sections_none">
                <div className="w-100 FromTopWave">
                <img className="w-100" src={FromTopWave} alt="" />
                </div>
                <div>
                <section className="form_bg margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                    <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-6 text-center text-lg-end mt-4 mt-md-0"
                            data-aos="fade-up-right"
                            data-aos-duration="1500"
                            >
                            <img
                                className="w-100"
                                src={CareerImg}
                                alt=""
                            />
                        </div>
                        <div
                        className="col-lg-6 mt-5"
                        data-aos="fade-up-left"
                        data-aos-duration="1500"
                        >
                            <h1 className="mb-2">
                            Attractive benefits
                            </h1>
                            <p className="main_para mt-2">
                            We are expanding bussiness and people to meet our clients' demands. You are warmly welcomed to join our team, where you got attractive benefits to support your daily performance.
                            </p>
                            <div className="d-flex align-items-center mt-4">
                                <span className="bg-info p-3 rounded-circle">
                                    <CareerLogo_1 />
                                </span>
                                <div className='ms-4 ms-md-5'>
                                    <h4 className="d-inline mb-0 text-danger">
                                        Flexible
                                    </h4>
                                    <p className="main_para mt-2">
                                    Working fewer hours than the standard workweek constitutes a reduced or part-time schedule.
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-4">
                                <span className="bg-warning p-3 rounded-circle opacity-50">
                                    <CareerLogo_2 />
                                </span>
                                <div className='ms-4 ms-md-5'>
                                    <h4 className="d-inline mb-0 text-danger">
                                    Growth
                                    </h4>
                                    <p className="main_para mt-2">
                                    At Global Technology, your teammates will be smart, experienced, and kind. They’ll be world-class engineers, expert testers, and brilliant developers. They’ll help you learn new skills, and they’ll support you when you need help.
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-4">
                                <span className="bg-primary p-3  rounded-circle">
                                    <CareerLogo_3 />
                                </span>
                                <div className='ms-4 ms-md-5'>
                                    <h4 className="d-inline mb-0 text-danger">
                                        Bonus
                                    </h4>
                                    <p className="main_para mt-2">
                                    When you join Global Technology, you begin a career path that can take you to amazing places. You’ll work with cutting-edge technology and use the most advanced methodologies. You’ll become a highly-skilled, highly-experienced professional.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>
                </div>
                <div className="w-100 FromBottomWave">
                <img className="w-100" src={FromBottomWave} alt="" />
                </div>
            </section>
            <section>
                    <div className="text-center mb-4 mb-lg-4">
                        <h4 className="d-inline mb-0 text-danger">
                        Careers
                        </h4>
                        <h1 className="mb-2">
                        Career Opportunities.
                        </h1>
                        <p className="d-inline mb-0">
                         Join the Global Technologies group. This is an ideal place for your career uplift.
                        </p>
                    </div>
                    <div className='container'>

                    <div className="row py-5 px-4 justify-content-center">
                    {opportunityCard.map((oprt, index)=> {
                        return (
                        <div className="col-xl-4 col-md-6 col-sm-9 col-11 my-sm-3 my-3">
                            <div
                            className={`teamcard h-100 position-relative cursor-pointer p-4 ${styles.career_box}`}
                            key={index}
                            >
                                <p className="mb-0 text-danger opacity-50 font-wight-bold mb-3">
                                {oprt.country}
                                </p>
                                <h5 className="mb-3">
                                {oprt.title}
                                </h5>
                                <p className="mb-4">
                                {oprt.subtitle}
                                </p>
                                <p className="mb-2">
                                {oprt.text}
                                </p>
                                <div className="mt-4 text-center">
                                <Link to="/contact">
                                    <button className="font-normal fw-bold text-white form_btn btn_hover">
                                    Apply Now
                                    </button>
                                </Link>
                                </div>
                            </div>
                        </div>
                        );
                    })}
                    </div>
                    </div>
                </section>
            <Footer />
        </div>
    );
};

export default CareerPage;