import React, { useEffect } from 'react';
import BgImg from '../../assets/img/hero/heroimg2.webp';
import ConsultImg from '../../assets/img/salesforce/consult.png';
import ExperienceImg from '../../assets/img/salesforce/experience.png';
import SupportImg from '../../assets/img/salesforce/support.png';
import { autoScrollUp } from '../common/autoScrollUp';
import Footer from '../common/Footer';
import Header from '../common/Header';
import TopHeader from '../homepage/TopHeader';
import styles from '../ServicePage/ServicePage.module.css';

const Salesforce = () => {
    useEffect(() => {
        autoScrollUp()
    },[])
    return (
        <div>
            <TopHeader />
            <Header />
            <section className="position-relative">
                <img className={`w-100 ${styles.service_img}`} src={BgImg} alt="" />
                <div className="position-absolute top-0 h-100 w-100 d-flex flex-column justify-content-center">
                <div className="container">
                    <div className="row justify-content-lg-between justify-content-center">
                        <h1 className="text-white fw-extrabold pe-lg-5 me-lg-5 text-center">
                        Services Salesforce 
                        </h1>
                    </div>
                </div>
                </div>
            </section>
            <section className="my-5 open_overlay_sections_none">
                <div>
                <section className=" margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                    <div id='salesforce-consulting' className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center">
                        <div
                            className="col-lg-6 text-center text-lg-start mt-4 mt-md-0"
                            data-aos="fade-up-right"
                            data-aos-duration="1500"
                            >
                            <img
                                className="w-75 "
                                src={ConsultImg}
                                alt=""
                            />
                        </div>
                        <div
                        className="col-lg-6 mt-5 mt-md-0"
                        data-aos="fade-up-left"
                        data-aos-duration="1500"
                        >
                            <div className="  mb-4 mb-lg-4">
                                <h4 className="d-inline mb-0 text-danger">
                                Implementation
                                </h4>
                            </div>
                            <h1 className="mb-5">
                            Salesforce Implementation
                            </h1>
                            {/* <h4 className="d-inline mb-0 text-danger mt-4">
                            Professional Design Agency to provide solutions
                            </h4> */}
                            <p className="main_para mt-4">
                            A Salesforce Implementation partner is part of the Salesforce partner program. <br /> The program allows partners to build and grow successful businesses while delivering the best solutions to its customers. <br /> L2 Global Technologies pvt ltd is a Salesforce Implementation partner
                            </p>
                            {/* <div className="mt-5">
                            <Link to="/contact">
                                <button className="font-normal fw-bold text-white form_btn btn_hover">
                                Contact Us &nbsp; {">"}
                                </button>
                            </Link>
                            </div> */}
                        </div>
                    </div>
                    </div>
                </section>
                </div>
            </section>
            <section className="my-5 open_overlay_sections_none">
                <div>
                <section className=" margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                    <div id='salesforce-implementation' className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center">
                        <div
                        className="col-lg-6  mt-5 mt-md-0 order-2 order-lg-1"
                        data-aos="fade-up-right"
                        data-aos-duration="1500"
                        >
                            <div className="  mb-4 mb-lg-4">
                                <h4 className="d-inline mb-0 text-danger">
                                Planning
                                </h4>
                            </div>
                            <h1 className="mb-5">
                            Expansion Planning
                            </h1>
                            <p className="main_para mt-4">
                            As your business changes, you need to make sure your Sales Cloud instance can change with you. That’s where we come in. <br /><br /> If you’re wanting to bring other departments into Sales Cloud, if you’re looking to integrate Sales Cloud with external systems or if you’re wanting to enhance your Quoting capabilities with Salesforce CPQ, we have the experience and expertise to make sure those initiatives are successful.
                            </p>
                        </div>
                        <div
                            className="col-lg-6 text-center text-lg-end order-1 order-lg-2 mt-4 mt-md-0"
                            data-aos="fade-up-left"
                            data-aos-duration="1500"
                            >
                            <img
                                className="w-75"
                                src={ExperienceImg}
                                alt=""
                            />
                        </div>
                    </div>
                    </div>
                </section>
                </div>
            </section>
            <section className="my-5 open_overlay_sections_none">
                <div>
                <section className=" margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                    <div id='salesforce-support' className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center">
                        <div
                            className="col-lg-6 text-center text-lg-start mt-4 mt-md-0"
                            data-aos="fade-up-right"
                            data-aos-duration="1500"
                            >
                            <img
                                className="w-75 "
                                src={SupportImg}
                                alt=""
                            />
                        </div>
                        <div
                        className="col-lg-6  mt-5 mt-md-0"
                        data-aos="fade-up-left"
                        data-aos-duration="1500"
                        >
                            <div className="  mb-4 mb-lg-4">
                                <h4 className="d-inline mb-0 text-danger">
                                Migration Support
                                </h4>
                            </div>
                            <h1 className="mb-5">
                            Salesforce Sales Cloud Lightning Migration
                            </h1>
                            <p className="main_para mt-4">
                            The Lightning Experience is an incredibly powerful UI/UX that offers admins and developers a litany of tools to improve the end user experience. <br /><br /> If you’re currently using Salesforce Classic, we can help map out your move to Lightning Experience and optimize it to fit your needs as they were met in Classic and beyond.
                            </p>
                        </div>
                    </div>
                    </div>
                </section>
                </div>
            </section>
            <section className='container'>
                    <div className="row py-5 px-4 justify-content-center">
                        <div className="col-xl-3 col-md-6 col-sm-9 col-11 my-sm-3 my-3">
                            <div
                            className={`teamcard h-100 position-relative cursor-pointer p-3 salse-grid-box`}>
                                <h5 className="mb-0 fw-bold mt-3 mb-4">
                                Elevated engagement
                                </h5>
                                <p className="mb-2 opacity-75">
                                Users create and manage their own profiles that they can tailor to their interests.
                                <br />
                                <br />
                                Easily share custom groups and personalized content for users to participate in discussions and form communities.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-sm-9 col-11 my-sm-3 my-3">
                            <div
                            className={`teamcard h-100 position-relative cursor-pointer p-3 salse-grid-box`}>
                                <h5 className="mb-0 fw-bold mt-3 mb-4">
                                Superior speed
                                </h5>
                                <p className="mb-2 opacity-75">
                                Enable solutions with built-in Lightning components tailored to your industry needs.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-sm-9 col-11 my-sm-3 my-3">
                            <div
                            className={`teamcard h-100 position-relative cursor-pointer p-3 salse-grid-box`}>
                                <h5 className="mb-0 fw-bold mt-3 mb-4">
                                Intelligent insights
                                </h5>
                                <p className="mb-2 opacity-75">
                                Einstein AI helps guide users to the most helpful, relevant content fast.
                                <br />
                                <br />
                                 Recommend groups, files, and more as the AI learns and tailors its responses to user needs.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-sm-9 col-11 my-sm-3 my-3">
                            <div
                            className={`teamcard h-100 position-relative cursor-pointer p-3 salse-grid-box`}>
                                <h5 className="mb-0 fw-bold mt-3 mb-4">
                                Instant integration
                                </h5>
                                <p className="mb-2 opacity-75">
                                Extend your business processes beyond Salesforce to seamlessly integrate with third-party systems.
                                <br />
                                <br />
                                Empower your teams to share files across multiple communities and third-party systems while maintaining a centralized view.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-sm-9 col-11 my-sm-3 my-3">
                            <div
                            className={`teamcard h-100 position-relative cursor-pointer p-3 salse-grid-box`}>
                                <h5 className="mb-0 fw-bold mt-3 mb-4">
                                Streamlined processes
                                </h5>
                                <p className="mb-2 opacity-75">
                                Easily integrate any record, share any file, infuse data from other systems.
                                <br />
                                <br />
                                Information remains consistent across multiple communities to ensure of-the-moment accuracy.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-sm-9 col-11 my-sm-3 my-3">
                            <div
                            className={`teamcard h-100 position-relative cursor-pointer p-3 salse-grid-box`}>
                                <h5 className="mb-0 fw-bold mt-3 mb-4">
                                Customer Community
                                </h5>
                                <p className="mb-2 opacity-75">
                                Reduce service calls by pointing customers toward FAQs, knowledge articles, or discussions.
                                <br />
                                <br />
                                Provide customers direct access to their data in a cohesive branded experience.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-sm-9 col-11 my-sm-3 my-3">
                            <div
                            className={`teamcard h-100 position-relative cursor-pointer p-3 salse-grid-box`}>
                                <h5 className="mb-0 fw-bold mt-3 mb-4">
                                Partner Community
                                </h5>
                                <p className="mb-2 opacity-75">
                                Streamline approvals, onboarding, quote processing, and more.
                                <br />
                                <br />
                                Provide full performance visibility with a single point of entry to dashboards, data, and files. Build a knowledge base of dashboards, data, and files.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-sm-9 col-11 my-sm-3 my-3">
                            <div
                            className={`teamcard h-100 position-relative cursor-pointer p-3 salse-grid-box`}>
                                <h5 className="mb-0 fw-bold mt-3 mb-4">
                                Employee Community
                                </h5>
                                <p className="mb-2 opacity-75">
                                Create a single source of truth for important news, updates, and company information.
                                <br />
                                <br />
                                Allow employees to collaborate in real time on projects using files from Microsoft OneDrive, SharePoint, Google Drive and Box.
                                </p>
                            </div>
                        </div> 
                    </div>
                </section>
            <Footer />
        </div>
    );
};

export default Salesforce;