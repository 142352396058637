import React from "react";
import { Link } from "react-router-dom";
import IndustriesBottomWave from "../../assets/img/industries/IndustriesBottomWave.webp";
import IndustriesImg from "../../assets/img/industries/IndustriesImg.webp";
import IndustriesTopWave from "../../assets/img/industries/IndustriesTopWave.webp";
import {
    IndustriesCircleIcon,
    IndustriesJobIcon,
    IndustriesScaleIcon
} from "../common/Icons";


const Industries = () => {
  return (
    <>
      <div
        className="w-100 open_overlay_sections_none FromTopWave"
      >
        <img className="w-100" src={IndustriesTopWave} alt="" />
      </div>
      <section className=" open_overlay_sections_none bg_pink pt-4 pt-sm-2 margin-top-minus-industries ">
        <div className="container">
          <div className="text-center mb-4 mb-lg-5 py-lg-0 py-5">
            <h2 className="d-inline main_heading px-3 py-2 bg_red mb-0">
              Industries
            </h2>
            <p className="text-center mt-4">
            Global Technology Ltd. Is providing the diverse solutions to address the requisites <br /> of the Information Technology industry. <Link className="links" to="/industries">See more</Link>
            </p>
          </div>
          <div className="row justify-content-center justify-content-lg-between align-items-center">
            <div
              className="col-lg-5 mb-4 mb-lg-0 order-lg-1 order-2"
              data-aos-duration="1500"
              data-aos="fade-up-right"
            >
              <div className="mt-sm-0 mt-4 moving_animation_img py-lg-0 pt-5">
                <img className="w-100" src={IndustriesImg} alt="" />
              </div>
            </div>
            <div
              className="col-lg-6 mt-sm-0 order-lg-2 order-1"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            >
              <div>
                <div className="d-flex mb-2 card_shadow p-3">
                  <div>
                    <span>
                      <IndustriesJobIcon />
                    </span>
                  </div>
                  <div>
                    <h2 className="main_para font-md fw-semibold">
                      Education
                    </h2>
                    <p className="main_para mb-0">
                    We deliver services to the Moodle™ software platform– to design and develop the best LMS platform for you.
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-2 card_shadow p-3">
                  <div>
                    <span>
                      <IndustriesScaleIcon />
                    </span>
                  </div>
                  <div>
                    <h2 className="main_para font-md fw-semibold">
                    Real Estate Services
                    </h2>
                    <p className="main_para mb-0">
                    We serve innovative solutions to real estate companies to help them align their businesses with industry 4.0 advancements. From process automation solutions to reality technologies to bring new avenues of engagement, we deliver holistic solutions.
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-3 card_shadow p-3">
                  <div>
                    <span>
                      <IndustriesCircleIcon />
                    </span>
                  </div>
                  <div>
                    <h2 className="main_para font-md fw-semibold">
                      Software/ ITES
                    </h2>
                    <p className="main_para mb-0">
                        We develop innovative digital solutions for ICT and software companies to adopt the latest technologies to solve complex business problems. We collaborate to build a mutually beneficial environment for both us and our clients.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className=" open_overlay_sections_none w-100 FromBottomWave">
        <img className="w-100" src={IndustriesBottomWave} alt="" />
      </div>
    </>
  );
};

export default Industries;
