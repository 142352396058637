import React, { useEffect } from 'react';
import FormBoy from "../../assets/img/form/FormBoy.jpg";
import FromBottomWave from "../../assets/img/form/FromBottomWave.webp";
import FromTopWave from "../../assets/img/form/FromTopWave.webp";
import { autoScrollUp } from '../common/autoScrollUp';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ContactIcon, EmailIcon, LinkedInIco, LocationIcon, MessengerIco } from '../common/Icons';
import MyLocation from '../common/MyLocation';
import TopHeader from '../homepage/TopHeader';
import styles from '../ServicePage/ServicePage.module.css';

const containerStyle = {
    width: '100%',
    height: '100%'
  };
const ContactPage = () => {
    useEffect(() => {
        autoScrollUp()
    },[])
    
    return (
        <>
        <TopHeader />
        <Header />
          {/* <section className="position-relative border border-danger" id="contact"> */}
          <section className={`position-relative ${styles.service_img}`} id="contact">
            {/* <img className={`w-100 ${styles.service_img}`} src={BgImg} alt="" /> */}
            <div className="position-absolute top-0 h-100 w-100 d-flex flex-column justify-content-center">
              <div className="container">
                <div className="row justify-content-lg-between justify-content-center">
                    <h1 className="text-white fw-extrabold pe-lg-5 me-lg-5 text-center">
                    Contact with L2 Global certified specialists
                    </h1>
                    <h5 className="text-center fw-normal text-white mt-1">
                    Our certified team is here to assist you with any Salesforce needs you may have.
                    </h5>
                </div>
              </div>
            </div>
          </section>
            <section className="my-5 open_overlay_sections_none">
                <div className="w-100 FromTopWave">
                <img className="w-100" src={FromTopWave} alt="" />
                </div>
                <div>
                <section className="form_bg margin-top-minus-form pt-3 pb-4 pt-sm-0 pb-sm-0">
                    <div className="container">
                    <div className="row">
                        <div
                        className="col-lg-6 mt-5"
                        data-aos="fade-up-right"
                        data-aos-duration="1500"
                        >
                            <h2 className="text-danger">
                            Take your digital future to the next level with L2Global
                            </h2>
                            <div className="row">
                                <div className="col-md-6 mt-3 mt-md-4">
                                <p className="main_para">
                                   Your Name *
                                </p>
                                <input
                                    type="text"
                                    className="form-control py-2"
                                    id="inputEmail4"
                                />
                                </div>
                                <div className="col-md-6 mt-3 mt-md-4">
                                <p className="main_para">
                                   Email Address *
                                </p>
                                <input
                                    type="text"
                                    className="form-control py-2"
                                    id="inputPassword4"
                                />
                                </div>
                                <div className="col-md-6 mt-3 mt-md-4">
                                <p className="main_para">
                                   Contact Number *
                                </p>
                                <input
                                    type="tel"
                                    className="form-control py-2"
                                    id="inputEmail4"
                                />
                                </div>
                                <div className="col-md-6 mt-3 mt-md-4">
                                <p className="main_para">
                                    Service Looking for *
                                </p>
                                <input
                                    type="tel"
                                    className="form-control py-2"
                                    id="inputPassword4"
                                />
                                </div>
                                <div className="mt-3 mt-md-4">
                                <p className="main_para">
                                    Project Details *
                                </p>
                                <textarea
                                    type="text"
                                    className="form-control py-2"
                                    id="exampleFormControlTextarea1"
                                    rows="6"
                                ></textarea>
                                </div>
                            </div>
                            <div className="mt-4 text-center">
                                <button className="font-normal fw-bold text-white form_btn btn_hover">
                                Submit
                                </button>
                            </div>
                        </div>
                        <div
                        className="col-lg-6 text-center text-lg-end mt-4 mt-md-0"
                        data-aos="fade-up-left"
                        data-aos-duration="1500"
                        >
                        <img
                            className="w-100"
                            src={FormBoy}
                            alt=""
                        />
                        </div>
                    </div>
                    </div>
                </section>
                </div>
                <div className="w-100 FromBottomWave">
                <img className="w-100" src={FromBottomWave} alt="" />
                </div>
            </section>
            <section>
            <div className="text-center pt-5 mb-lg-5 container">
                <h2 className="d-inline main_heading px-3 py-2 bg_red mb-0">
                Location
                </h2>
                <p className="text-center mt-4">
                Browse through the most comprehensive map to get location of L2 Global Technology Ltd.
                </p>
            </div>
            
            
            <div
                className="container open_overlay_sections_none contact-section pt-5 position-relative"
                id="Contact"
                >
                    <div className="">
                        <div className="row justify-content-between mb-sm-5 mb-sm-3">
                            <div
                                className="col-lg-6 mt-lg-0 mt-5"
                                data-aos="fade-right"
                                data-aos-duration="1500"
                                >
                                <div className="w-100 MapImg2">
                                    <MyLocation containerStyle={containerStyle} />
                                </div>
                            </div>
                            <div
                            className="col-lg-6 mt-lg-0 mt-5"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                            >
                                <div className='w-100 text-center'>
                                    <div className='border border-2 border-black p-4'>
                                        <h4 className="mb-2 text-black">
                                        India
                                        </h4>
                                        <h4 className="fw-light mt-2 text-black">
                                            1-8, Tempalli, Gannavaram,
                                        </h4>
                                        <h4 className="fw-light mt-2 text-black">
                                            Krishna District, Andhra Pradesh – 521286
                                        </h4>
                                        <h4 className="fw-light mt-2 text-warning">
                                            Contactus@l2global.in
                                        </h4>
                                    </div>
                                </div>
                                <div className='w-100 text-center mt-4'>
                                    <div className='border border-2 border-black p-4'>
                                        <h4 className="mb-2 text-black">
                                        UK
                                        </h4>
                                        <h4 className="fw-light mt-2 text-black">
                                            Hounslow, London. 
                                        </h4>
                                        <h4 className="fw-light mt-2 text-warning">
                                            Contactus@l2global.in
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='container py-5'>
                    <div className="row justify-content-between">
                        <div className="col-lg-3 mt-lg-0 my-1 p-3">
                            <div className='bg-body rounded shadow p-4 contact-info'>
                                <h4 className="mb-2 text-black">
                                Message Us
                                </h4>
                                <p className="main_para mt-2 mb-0">
                                Feel free to reach out  <br /> to us on social.
                                </p>
                                <div className='mt-3'>
                                    <LinkedInIco />
                                    <MessengerIco />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-lg-0 my-1 p-3">
                            <div className='bg-body rounded shadow p-4 text-center contact-info'>
                                <ContactIcon />
                                <h5 className="my-2 text-black">
                                +44 74425 86325
                                </h5>
                                <p className="main_para my-2 mb-0">
                                Monday - Friday from 9am - 5pm
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-lg-0 my-1 p-3">
                            <div className='bg-body rounded shadow p-4 text-center contact-info'>
                                <LocationIcon />
                                <h5 className="my-2 text-black">
                                1-8, Tempalli(P), 
                                </h5>
                                <p className="main_para mt-2 mb-0">
                                Gannavaram(M), Krishna District,
                                </p>
                                <p className="main_para my-2 mb-0">
                                Andhra Pradesh – 521286
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-lg-0 my-1 p-3">
                            <div className='bg-body rounded shadow p-4 text-center contact-info'>
                                <EmailIcon />
                                <h5 className="my-2 text-black">
                                Contactus@l2global.in
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          <Footer />
        </>
    );
};

export default ContactPage;
