import { GoogleMap, LoadScript } from '@react-google-maps/api';
import React from 'react';


const center = {
    lat: 51.470106,
    lng: -0.361635
};

function MyComponent({containerStyle}) {
    
    return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={16}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <></>
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(MyComponent);
