import React from "react";
// import { TeamData } from "../common/Content";
import CountUp from "react-countup";
import projects_img from '../../assets/img/projects/completed.svg';
import customers_img from '../../assets/img/projects/customers.svg';
import employees_img from '../../assets/img/projects/employees.svg';
import TeamCards from "./TeamCards";
import TrustedCompany from "./TrustedCompany";

const Team = () => {
  return (
    <div className="open_overlay_sections_none">
      <section className="pt-4 pt-sm-5">
        <div className="container  py-5">
          <div className="row justify-content-lg-between">
            <div className="col-12 col-sm-6 col-lg-4 text-center text-lg-start">
              <h3 className="main_heading text-black">
                We are proud of
                <span className="d-xl-block">our creative team</span>
              </h3>
              <p className="main_para">
                Just sit back and relax while we take care
                <span className="d-xl-block">of your business needs.</span>
              </p>
            </div>
            <div className="col-12 col-sm-6 col-lg-2 text-center mt-4 mt-sm-0">
              <div>
                <span>
                  <img style={{height:'80px'}} src={projects_img} alt="" />
                </span>
              </div>
              <h3 className="main_heading text-black mb-0 mt-3">
                <CountUp 
                    start={0}
                    end={434}
                    duration={3}
                    enableScrollSpy
                />
              </h3>
              <p className="font-lg fw-normal">Complete Projects</p>
            </div>
            <div className="col-12 col-sm-6 col-lg-2 text-center mt-4 mt-lg-0">
              <div>
                <span>
                  <img style={{height:'80px'}} src={customers_img} alt="" />
                </span>
              </div>
              <h3 className="main_heading text-black mb-0 mt-3">
              <CountUp 
                    start={0}
                    end={3532}
                    duration={3}
                    enableScrollSpy
                />
                </h3>
              <p className="font-lg fw-normal">Happy Custumers</p>
            </div>
            <div className="col-12 col-sm-6 col-lg-2 text-center mt-4 mt-lg-0">
              <div>
                <span>
                  <img style={{height:'80px'}} src={employees_img} alt="" />
                </span>
              </div>
              <h3 className="main_heading text-black mb-0 mt-3">
              <CountUp 
                    start={0}
                    end={241}
                    duration={3}
                    enableScrollSpy
                />
              </h3>
              <p className="font-lg fw-normal">Expert Employees</p>
            </div>
          </div>
          <TrustedCompany />
          <TeamCards />
        </div>
      </section>
    </div>
  );
};

export default Team;
