import React from "react";
import {
    TopHeaderFacebookIcon,
    TopHeaderInstagram,
    TopHeaderLinkedIn,
    TopHeaderTwitterIcon,
    TopHeaderYouTubeIcon
} from "../common/Icons";

const TopHeader = () => {
  return (
    <div className="top-header-section py-3 open_overlay_sections_none">
      <div className="container d-flex justify-content-between  align-items-center flex-column flex-lg-row">
        <div>
          <a href="https://twitter.com/" target="_blank" rel="noreferrer">
            <TopHeaderTwitterIcon />
          </a>
          <a href="https://www.linkedin.com/company/l2-global-technologies-pvt-ltd/?viewAsMember=true" target="_blank" rel="noreferrer">
            <TopHeaderLinkedIn />
          </a>
          <a href="https://www.facebook.com/people/L2-Global-Technologies-Pvt-Limited/100088223491869/" target="_blank" rel="noreferrer">
            <TopHeaderFacebookIcon />
          </a>

          <a href="https://www.instagram.com/?hl=en" target="_blank" rel="noreferrer">
            <TopHeaderInstagram />
          </a>
          <a href="https://www.youtube.com/@l2globaltechnologies476" target="_blank" rel="noreferrer">
            <TopHeaderYouTubeIcon />
          </a>
        </div>
        <p className="font-sm text-white mb-0 mt-3 mt-lg-0">
        + 44 &nbsp; 74425 86325 &nbsp;  + 91 &nbsp; 97403 39495 &nbsp; Contactus@l2global.in
        </p>
        {/* <p className="font-sm text-white mb-0 mt-2 mt-lg-0">
          Material Downloads
        </p> */}
      </div>
    </div>
  );
};

export default TopHeader;
