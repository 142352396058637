import React from "react";
import { Link } from "react-router-dom";
import ServicesImg from "../../assets/img/service/ServicesImg.webp";
import {
    ServicesCircleIcon,
    ServicesCloudIcon,
    ServicesHomeIcon,
    ServicesMobileIcon
} from "../common/Icons";

const Services = ({services, animate}) => {
  return (
    <>
      <section className="py-5 open_overlay_sections_none">
        <div className="container">
          {services && <div className="text-center mb-5">
            <h2 className="d-inline main_heading px-3 py-2 bg_red ">
              {services}
            </h2>
            <p className="text-center mt-4">
            The full service we are offering is specifically designed to meet your <br /> business needs and projects. <Link className="links" to="/services">See more</Link>
            </p>
          </div>}
          <div className="row justify-content-center justify-content-lg-between align-items-center">
            <div
              className="col-lg-6 mb-lg-0"
              data-aos="fade-up-right"
              data-aos-duration="1500"
            >
              <div>
                <div className="d-flex mb-3 mb-lg-4 card_shadow p-3">
                  <div>
                    <span>
                      <ServicesCloudIcon />
                    </span>
                  </div>
                  <div className="">
                    <h2 className="main_para font-md fw-semibold">
                    Research and Content Planning
                    </h2>
                    <p className="main_para mb-0">
                    The very first and very important step is research and planning. We create the structure and we follow the structure throughout.
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-3 mb-lg-4 card_shadow p-3">
                  <div>
                    <span>
                      <ServicesMobileIcon />
                    </span>
                  </div>
                  <div className="ms-3">
                    <h2 className="main_para font-md fw-semibold">
                    Implementation or Execution
                    </h2>
                    <p className="main_para mb-0">
                    After all the planning and we directly jump on to the execution part where we use best standardized technique while writing codes.
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-3 mb-lg-4 card_shadow p-3">
                  <div>
                    <span>
                      <ServicesCircleIcon />
                    </span>
                  </div>
                  <div>
                    <h2 className="main_para font-md fw-semibold">
                    Product Prototyping and Feedback
                    </h2>
                    <p className="main_para mb-0">
                    In this step, we create a replica of the product which is used as an early model to test your product and get feedback from you.
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-3 mb-lg-4 card_shadow p-3">
                  <div>
                    <span>
                      <ServicesHomeIcon />
                    </span>
                  </div>
                  <div>
                    <h2 className="main_para font-md fw-semibold">
                    Deliver the Final Product
                    </h2>
                    <p className="main_para mb-0">
                    In the very last step, we make sure that we worked on all the feedback we received and try to give the experience to our all the clients.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-5"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            >
              <div className={`${animate} py-lg-0 pt-5`}>
                <img className="w-100" src={ServicesImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
